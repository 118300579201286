import { IncomingMessage } from "http";
import * as Sentry from "@sentry/nextjs";

export function initializeSentry(dsn?: string, env?: string) {
  if (dsn) {
    Sentry.init({
      dsn,
      enabled: process.env.NODE_ENV === "production",
      environment: env,
    });
  }
}

export function setupSentryRequest(req: IncomingMessage) {
  Sentry.configureScope((scope) => {
    scope.setTag("host", req.headers["host"]);
    scope.setTag("url", req.url);
    scope.setTag("method", req.method);
    scope.setContext("headers", req.headers);
    scope.setTag("runtimeEngine", process.browser ? "browser" : "server");
  });
}

export default Sentry;
