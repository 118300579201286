export enum ApiMeetingKeys {
  fetch = "meeting/fetch",
  fetchPolls = "meeting/fetchPolls",
  submitPoll = "meeting/submitPoll",
  fetchHostMeeting = "meeting/fetchHostMeeting",
  fetchResults = "meeting/fetchResults",
  fetchClickmeeting = "meeting/fetchClickmeeting",
  getInvitations = "meeting/getInvitations",
  setLocality = "meeting/setLocality",
}
