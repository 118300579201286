import { all, call, put, takeLatest } from "@redux-saga/core/effects";
import { fetchApi } from "../api/sagas";
import { snackbarActions } from "../snackbar";
import { ApiUnsubscribeKeys } from "./keys";
import { unsubscribeActions } from ".";

function* unsubscribeSaga(
  action: ReturnType<typeof unsubscribeActions.unsubscribe>
) {
  try {
    yield call(fetchApi, `/api/unsubscribe?token=${action.payload}`, {
      key: ApiUnsubscribeKeys.unsubscribe,
    });
  } catch (error) {
    yield put(snackbarActions.displayError(error));
  }
}

export function* unsubscribeSagas() {
  yield all([takeLatest(unsubscribeActions.unsubscribe.type, unsubscribeSaga)]);
}
