import { all } from "@redux-saga/core/effects";
import { configureStore } from "@reduxjs/toolkit";
import { createWrapper } from "next-redux-wrapper";
import { combineReducers, Store } from "redux";
import createSagaMiddleware, { Task } from "redux-saga";

import { apiReducer } from "./modules/api";
import { authReducer } from "./modules/auth";
import { authSagas } from "./modules/auth/sagas";
import { billingReducer } from "./modules/billing";
import { billingSagas } from "./modules/billing/sagas";
import { environmentReducer } from "./modules/enviroment";
import { eventsReducer } from "./modules/events";
import { eventsSagas } from "./modules/events/sagas";
import { joinPublicReducer } from "./modules/joinPublic";
import { joinPublicSagas } from "./modules/joinPublic/sagas";
import { meetingReducer } from "./modules/meeting";
import { meetingSagas } from "./modules/meeting/sagas";
import { organizationUsersReducer } from "./modules/organizationUsers";
import { organizationUsersSagas } from "./modules/organizationUsers/sagas";
import { pollsReducer } from "./modules/polls";
import { pollsSagas } from "./modules/polls/sagas";
import { preboardReducer } from "./modules/preboard";
import { preboardSagas } from "./modules/preboard/sagas";
import { propertiesReducer } from "./modules/properties";
import { propertiesSagas } from "./modules/properties/sagas";
import { snackbarReducer } from "./modules/snackbar";
import { unsubscribeReducer } from "./modules/unsubscribe";
import { unsubscribeSagas } from "./modules/unsubscribe/sagas";
import { userReducer } from "./modules/user";
import { userSagas } from "./modules/user/sagas";
import { verifyReducer } from "./modules/verify";
import { verifySagas } from "./modules/verify/sagas";
import { votersReducer } from "./modules/voters";
import { votersSagas } from "./modules/voters/sagas";

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  verify: verifyReducer,
  api: apiReducer,
  events: eventsReducer,
  polls: pollsReducer,
  meeting: meetingReducer,
  snackbar: snackbarReducer,
  preboard: preboardReducer,
  environment: environmentReducer,
  voters: votersReducer,
  joinPublic: joinPublicReducer,
  properties: propertiesReducer,
  billing: billingReducer,
  organizationUsers: organizationUsersReducer,
  unsubscribe: unsubscribeReducer,
});

function* rootSaga() {
  yield all([
    authSagas(),
    userSagas(),
    verifySagas(),
    eventsSagas(),
    pollsSagas(),
    meetingSagas(),
    preboardSagas(),
    votersSagas(),
    joinPublicSagas(),
    propertiesSagas(),
    billingSagas(),
    organizationUsersSagas(),
    unsubscribeSagas(),
  ]);
}

export default function makeStore() {
  const sagaMiddleware = createSagaMiddleware();
  const store = configureStore({
    reducer: rootReducer,
    middleware: [sagaMiddleware],
  });

  if (process.browser) {
    (store as ISagaStore).sagaTask = sagaMiddleware.run(rootSaga);
  }

  return store;
}

export type RootState = ReturnType<typeof rootReducer>;

export interface ISagaStore extends Store {
  sagaTask?: Task;
}

export const wrapper = createWrapper<RootState>(makeStore);
