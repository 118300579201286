import { RootState } from "../../index";

const selectVotersState = (state: RootState) => state.voters;

export const selectVoters = (state: RootState) =>
  selectVotersState(state).voters;
export const selectVotersEventId = (state: RootState) =>
  selectVotersState(state).eventId;
export const selectProxyCount = (state: RootState) =>
  selectVoters(state)?.reduce(
    (proxies, voter) => proxies + +(voter.proxy || !!voter.mandate),
    0
  );
