export enum ApiOrganizationUsersKeys {
  fetchUsers = "organizationUsers/fetchUsers",
  inviteUser = "organizationUsers/inviteUser",
  inviteUsers = "organizationUsers/inviteUsers",
  updateUser = "organizationUsers/updateUser",
  deleteUser = "organizationUsers/deleteUser",
}

export function buildUpdateUserKey(userId: string) {
  return `${ApiOrganizationUsersKeys.updateUser}/${userId}`;
}

export function buildDeleteUserKey(userId: string) {
  return `${ApiOrganizationUsersKeys.deleteUser}/${userId}`;
}
