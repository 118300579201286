import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { AppContext, AppProps } from "next/app";
import { NextRouter } from "next/router";
import { appWithTranslation } from "next-i18next";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import nextI18NextConfig from "../next-i18next.config.js";
import {
  IEnvironment,
  IEnvironmentResponse,
} from "../src/api/types/environment";
import SnackbarProvider from "../src/components/SnackbarProvider";
import { wrapper } from "../src/store";
import { authActions } from "../src/store/modules/auth";
import { environmentActions } from "../src/store/modules/enviroment";
import { getCookie, setCookie } from "../src/utils/cookies";
import { initializeGoogleAnalytics, initGa } from "../src/utils/ga";
import { initializeHotjar } from "../src/utils/hotjar";
import {
  initializeSentry,
  setupSentryRequest,
} from "../src/utils/monitoring/sentry";
import theme from "../src/utils/theme";

interface IRootAppProps {
  environment?: IEnvironment;
}

function RootApp({
  Component,
  pageProps,
  router,
  environment,
}: AppProps & IRootAppProps) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider>
        <Global router={router} environment={environment} />
        <Component {...pageProps} />
      </SnackbarProvider>
    </ThemeProvider>
  );
}

async function getEnvironment() {
  const response = await fetch(
    `${
      process.browser ? "" : `http://localhost:${process.env.PORT}`
    }/api/environment`
  );

  const { environment } = (await response.json()) as IEnvironmentResponse;
  return environment;
}

RootApp.getInitialProps = async ({
  ctx: { req, res, pathname },
  router,
}: AppContext): Promise<IRootAppProps> => {
  // Don't preload environment if generating on build
  if (router.asPath === "/500") {
    return {};
  }

  if (req && res) {
    res.locals ??= {};

    res.locals.route = pathname;

    const environment = await getEnvironment();

    initializeReporting(environment);
    setupSentryRequest(req);

    return {
      environment,
    };
  }

  return {};
};

interface IGlobalProps {
  router: NextRouter;
  environment?: IEnvironment;
}

function Global({ router, environment }: IGlobalProps) {
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.dev ??= {};

    dispatch(authActions.whoami());

    initGa(router);

    const jssStyles = document.querySelector("#jss-server-side");
    jssStyles?.parentElement?.removeChild(jssStyles);

    const locale = getCookie("NEXT_LOCALE");

    if (!locale) {
      const preferredLanguage =
        (navigator.languages?.length
          ? navigator.languages
              .find((language) =>
                router.locales.includes(language.split("-")[0])
              )
              ?.split("-")[0]
          : navigator.language?.split("-")[0]) || "";

      const language = router.locales.includes(preferredLanguage)
        ? preferredLanguage
        : router.defaultLocale;

      setCookie("NEXT_LOCALE", language, 365);

      router.push(router, undefined, {
        locale: language,
      });
    } else if (router.locale !== locale) {
      router.push(router, undefined, {
        locale,
      });
    }
  }, []);

  useEffect(() => {
    if (initialized) {
      return;
    }

    // Initialize environment/reporting
    if (environment) {
      dispatch(environmentActions.setEnvironment(environment));
      initializeReporting(environment);
      setInitialized(true);
    } else {
      (async () => {
        const environment = await getEnvironment();

        dispatch(environmentActions.setEnvironment(environment));
        initializeReporting(environment);
        setInitialized(true);
      })();
    }
  }, [environment, initialized]);

  return null;
}

function initializeReporting(environment: IEnvironment) {
  if (process.browser) {
    console.log(`Propty Vote ${environment.version || "no-version"}`);

    if (window.disableTracking) {
      if (environment.googleAnalytics.trackingId) {
        window[`ga-disable-${environment.googleAnalytics.trackingId}`] = true;
      }

      return;
    }

    // Can only be ran in browser
    if (environment.googleAnalytics.trackingId) {
      initializeGoogleAnalytics(
        environment.googleAnalytics.trackingId,
        window.location.pathname
      );
    }

    if (environment.hotjar.id) {
      initializeHotjar(environment.hotjar.id);
    }
  }

  initializeSentry(environment.sentry.dsn, environment.env);

  if (
    environment.datadog.applicationId &&
    environment.datadog.clientToken &&
    environment.datadog.site
  ) {
    datadogLogs.init({
      applicationId: environment.datadog.applicationId,
      clientToken: environment.datadog.clientToken,
      site: environment.datadog.site,
      service: environment.service,
      env: environment.env,
      version: environment.version,
      silentMultipleInit: true,
      trackInteractions: true,
    });

    datadogRum.init({
      applicationId: environment.datadog.applicationId,
      clientToken: environment.datadog.clientToken,
      site: environment.datadog.site,
      service: environment.service,
      env: environment.env,
      version: environment.version,
      trackInteractions: true,
      silentMultipleInit: true,
      allowedTracingOrigins: [environment.baseUrl],
    });
  }
}

export default appWithTranslation(
  wrapper.withRedux(RootApp) as React.FunctionComponent,
  nextI18NextConfig
);
