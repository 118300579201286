import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPropertyPayload } from "../../../api/types/event";
import { IAddressAutocompleteQueryResult } from "../../../api/types/mapbox";
import { IProperty } from "../../../api/types/property";

interface IPropertiesState {
  properties?: Array<IProperty>;
  autocompleteResults?: Array<IAddressAutocompleteQueryResult>;
}

const initialState: IPropertiesState = {};

const propertiesSlice = createSlice({
  name: "properties",
  initialState,
  reducers: {
    addProperty(_, __: PayloadAction<IPropertyPayload>) {},
    fetchProperties() {},
    setProperties(state, action: PayloadAction<Array<IProperty>>) {
      state.properties = action.payload;
    },
    resetAutocompleteResults(state) {
      state.autocompleteResults = undefined;
    },
    fetchAutocompleteResults(_, __: PayloadAction<string>) {},
    setAutocompleteResults(
      state,
      action: PayloadAction<Array<IAddressAutocompleteQueryResult>>
    ) {
      state.autocompleteResults = action.payload;
    },
  },
});

export const propertiesReducer = propertiesSlice.reducer;
export const propertiesActions = propertiesSlice.actions;
