import { all, call, put, takeLatest } from "@redux-saga/core/effects";
import {
  IPricesResponse,
  IRedirectResponse,
  ITierPricesResponse,
} from "../../../types/billing";
import { fetchApi, FetchMethods, IFetchResult } from "../api/sagas";
import { snackbarActions } from "../snackbar";
import { ApiBillingKeys } from "./keys";
import { billingActions } from "./index";

function* subscribeSaga(action: ReturnType<typeof billingActions.subscribe>) {
  try {
    yield call(fetchApi, `/api/billing/subscribe`, {
      method: FetchMethods.Post,
      key: ApiBillingKeys.subscribe,
      body: action.payload,
      callback: function (response: IFetchResult<IRedirectResponse>) {
        location.href = response.body.url;
      },
    });
  } catch (error) {
    yield put(snackbarActions.displayError(error));
  }
}

function* portalSaga(_: ReturnType<typeof billingActions.portal>) {
  try {
    yield call(fetchApi, `/api/billing/portal`, {
      method: FetchMethods.Post,
      key: ApiBillingKeys.portal,
      callback: function (response: IFetchResult<IRedirectResponse>) {
        location.href = response.body.url;
      },
    });
  } catch (error) {
    yield put(snackbarActions.displayError(error));
  }
}

function* fetchPricesSaga(_: ReturnType<typeof billingActions.fetchPrices>) {
  try {
    yield call(fetchApi, `/api/billing/prices`, {
      method: FetchMethods.Get,
      key: ApiBillingKeys.fetchPrices,
      callback: function* (response: IFetchResult<IPricesResponse>) {
        yield put(billingActions.setPrices(response.body));
      },
    });
  } catch (error) {
    yield put(snackbarActions.displayError(error));
  }
}

function* fetchProductPricesSaga(
  _: ReturnType<typeof billingActions.fetchProductPrices>
) {
  try {
    yield call(fetchApi, `/api/billing/productPrices`, {
      method: FetchMethods.Get,
      key: ApiBillingKeys.fetchProductPrices,
      callback: function* (response: IFetchResult<ITierPricesResponse>) {
        yield put(billingActions.setProductPrices(response.body));
      },
    });
  } catch (error) {
    yield put(snackbarActions.displayError(error));
  }
}

export function* billingSagas() {
  yield all([
    takeLatest(billingActions.subscribe.type, subscribeSaga),
    takeLatest(billingActions.portal.type, portalSaga),
    takeLatest(billingActions.fetchPrices.type, fetchPricesSaga),
    takeLatest(billingActions.fetchProductPrices.type, fetchProductPricesSaga),
  ]);
}
