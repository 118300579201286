import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IEnvironment } from "../../../api/types/environment";

interface IEnvironmentState {
  environment?: IEnvironment;
}

const initialState: IEnvironmentState = {};

const environmentSlice = createSlice({
  name: "environment",
  initialState,
  reducers: {
    setEnvironment(state, action: PayloadAction<IEnvironment>) {
      state.environment = action.payload;
    },
  },
});

export const environmentReducer = environmentSlice.reducer;
export const environmentActions = environmentSlice.actions;
