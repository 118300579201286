import { datadogLogs } from "@datadog/browser-logs";
import { select, put, call } from "@redux-saga/core/effects";
import { IErrorsResponse } from "../../../api/types";
import { selectSignature } from "../auth/selectors";
import { ISession } from "../user";
import { selectSession } from "../user/selectors";
import { apiActions } from "./index";

export class ApiErrors extends Error {
  constructor(
    public readonly errors: string[],
    public readonly statusCode?: number
  ) {
    super(errors.join("\n"));

    this.errors = errors;

    Object.setPrototypeOf(this, ApiErrors.prototype);
  }
}

export enum FetchMethods {
  Get = "GET",
  Post = "POST",
  Delete = "DELETE",
}

export interface IFetchOptions<BodyType> {
  body?: unknown;
  rawBody?: BodyInit | null;
  method?: FetchMethods;
  headers?: HeadersInit;
  token?: string;
  key?: string;
  noParse?: boolean;
  callback?: (response: IFetchResult<BodyType>) => Generator | void;
  errorCallback?: (error: unknown) => Generator;
  expectedErrors?: string[];
}

export interface IFetchResult<BodyType> {
  body: BodyType;
  response: Response;
}

export function* fetchApi<BodyType = Record<string, unknown>>(
  path: string,
  options: IFetchOptions<BodyType> = {}
) {
  const session: ISession | undefined = yield select(selectSession);
  const method = options.method || FetchMethods.Get;

  try {
    const token: string | undefined = options.token || session?.token;
    const signature: string | undefined = yield select(selectSignature);

    const headers: HeadersInit = {
      accept: "application/json",
      ...options.headers,
    };

    if (token && !headers["authorization"]) {
      headers["authorization"] = `Bearer ${token}`;
    }

    if (options.body && !headers["content-type"]) {
      headers["content-type"] = "application/json";
    }

    if (signature) {
      headers["x-signature"] = signature;
    }

    if (options.key) {
      yield put(apiActions.startRequest(options.key));
    }

    datadogLogs.logger.info("Starting API request", {
      path,
      userId: session?.user?.id,
      method,
    });

    const response: Response = yield fetch(path, {
      headers,
      body:
        options.rawBody ||
        (options.body ? JSON.stringify(options.body) : undefined),
      method: method,
    });

    const body: BodyType | IErrorsResponse | undefined = options.noParse
      ? undefined
      : yield response.json();

    if (typeof body === "object" && "errors" in body) {
      throw new ApiErrors(body.errors, response.status);
    }

    datadogLogs.logger.info("API request success", {
      path,
      statusCode: response.status,
      userId: session?.user?.id,
      method,
    });

    const result: IFetchResult<BodyType> = {
      body,
      response,
    };

    if (options.callback) {
      try {
        yield call(() => options.callback(result));
      } catch {}
    }

    if (options.key) {
      yield put(apiActions.completeRequest({ key: options.key }));
    }

    return result;
  } catch (error) {
    const apiErrors = error instanceof ApiErrors ? error : undefined;

    if (
      !options.expectedErrors ||
      !apiErrors?.errors.every((error) =>
        options.expectedErrors.includes(error)
      )
    ) {
      datadogLogs.logger.info("API request failure", {
        path,
        statusCode: apiErrors?.statusCode,
        userId: session?.user?.id,
        method,
      });
    }

    if (options.errorCallback) {
      try {
        yield call(() => options.errorCallback(error));
      } catch {}
    }

    if (options.key) {
      const errors = apiErrors ? error.errors : [error.toString()];

      yield put(
        apiActions.completeRequest({
          key: options.key,
          errors,
        })
      );
    }

    throw error;
  }
}
