import { RootState } from "../..";

const selectMeetingState = (state: RootState) => state.meeting;

export const selectMeeting = (state: RootState) =>
  selectMeetingState(state).meeting;

export const selectHostMeeting = (state: RootState) =>
  selectMeetingState(state).hostMeeting;

export const selectCommonMeeting = (state: RootState) =>
  selectHostMeeting(state) || selectMeeting(state);

export const selectPolls = (state: RootState) =>
  selectMeetingState(state).polls;

export const selectMeetingAgenda = (state: RootState) =>
  selectMeeting(state).event.agenda;

export const selectClickmeeting = (state: RootState) =>
  selectMeetingState(state).clickmeeting;
