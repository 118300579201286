import { Language } from "@prisma/client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IEditOrganizationSettingsPayload,
  IEditProfileSettingsPayload,
} from "../../../utils/validation/user";
import { IOrganization, IUser } from "../auth/types";

export interface ISession {
  token: string;
  user: IUser;
}

interface IUserState {
  session?: ISession;
}

const initialState: IUserState = {};

const userSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    editLanguage(_, __: PayloadAction<Language>) {},
    editProfileSettings(_, __: PayloadAction<IEditProfileSettingsPayload>) {},
    editOrganizationSettings(
      _,
      __: PayloadAction<IEditOrganizationSettingsPayload>
    ) {},
    setSession(state, action: PayloadAction<ISession>) {
      state.session = action.payload;
    },
    setUser(state, action: PayloadAction<IUser>) {
      if (state.session) {
        state.session.user = action.payload;
      }
    },
    setOrganization(state, action: PayloadAction<IOrganization>) {
      if (state.session) {
        state.session.user.organization = action.payload;
      }
    },
    clearOrganizationAvatar(_, __: PayloadAction) {},
  },
});

export const userReducer = userSlice.reducer;
export const userActions = userSlice.actions;
