export enum ApiPollsKeys {
  fetch = "polls/fetch",
  create = "polls/create",
  createQuestion = "polls/createQuestion",
  edit = "polls/edit",
  fetchResults = "polls/fetchResults",
  fetchAllResults = "polls/fetchAllResults",
  fetchAllVotingResults = "polls/fetchAllVotingResults",
  sendPoll = "polls/sendPoll",
  endPoll = "polls/endPoll",
  clearOptionAvatar = "polls/clearOptionAvatar",
  sendPolls = "event/sendPolls",
}

// Unique key per question for unique loading indicators/errors
export function buildCreateQuestionOptionKey(questionId: string) {
  return `polls/createQuestionOption/${questionId}`;
}

export function buildFetchPollsKey(eventId: string) {
  return `${ApiPollsKeys.fetch}/${eventId}`;
}

export function buildFetchAllVotingResultsKey(pollId: string) {
  return `${ApiPollsKeys.fetchAllVotingResults}/${pollId}`;
}

export function buildSendPollsKey(eventId: string) {
  return `${ApiPollsKeys.sendPolls}/${eventId}`;
}
