const bowserPromise = import("bowser");

async function getBrowser() {
  const bowser = (await bowserPromise).default;
  return bowser.getParser(window.navigator.userAgent);
}

export async function getBowserDetails() {
  const browser = await getBrowser();

  const browserName = `${browser.getBrowserName()} ${browser.getBrowserVersion()}`;
  const osName = [browser.getOSName(), browser.getOSVersion()]
    .filter(Boolean)
    .join(" ");
  return {
    os: osName,
    browser: browserName,
  };
}

export async function getIsUnsupportedBrowser() {
  const browser = await getBrowser();

  return browser.satisfies({
    ie: ">=0",
    safari: ">=0",

    ios: ">=0",

    chrome: "<53",
    chromium: "<53",
    firefox: "<76",
    edge: "<80",

    mobile: {
      firefox: ">=0",
    },
  });
}

export async function getIsAppleMobileDevice() {
  const browser = await getBrowser();

  return browser.getOSName(true) === "ios";
}
