import { all, call, put, select, takeLatest } from "@redux-saga/core/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  ICreatePollResponse,
  IFetchPollsResponse,
  IPoll,
  IPollResponse,
  IPollsResultsResponse,
  IPollVotingResults,
  IPollVotingResultsResponse,
} from "../../../api/types/poll";
import { apiActions } from "../api";
import { ApiErrors, fetchApi, FetchMethods, IFetchResult } from "../api/sagas";
import {
  ApiPollsKeys,
  buildCreateQuestionOptionKey,
  buildFetchAllVotingResultsKey,
  buildFetchPollsKey,
  buildSendPollsKey,
} from "./keys";
import { selectPolls, selectSelected } from "./selectors";
import {
  ICreatePoll,
  ICreatePollQuestion,
  ICreatePollQuestionOption,
  IEditPoll,
  IEditPollQuestion,
  IEditPollQuestionOption,
  IPollAction,
  IPollEventAction,
  IPollQuestionAction,
  IPollQuestionOptionAction,
} from "./types";
import { pollsActions } from ".";

// TODO: Scoped keys

function* fetchSaga(action: PayloadAction<IPollEventAction>) {
  try {
    yield call(fetchApi, `/api/event/${action.payload.eventId}/admin/polls`, {
      key: buildFetchPollsKey(action.payload.eventId),
      token: action.payload.adminKey,
      callback: function* (response: IFetchResult<IFetchPollsResponse>) {
        yield put(pollsActions.setPolls(response.body.polls));
      },
    });
  } catch {}
}

function* createPollSaga(action: PayloadAction<ICreatePoll>) {
  try {
    yield call(fetchApi, `/api/event/${action.payload.eventId}/admin/polls`, {
      key: ApiPollsKeys.create,
      method: FetchMethods.Post,
      body: action.payload.values,
      token: action.payload.adminKey,
      callback: function* (response: IFetchResult<ICreatePollResponse>) {
        yield put(pollsActions.setPolls(response.body.polls));
        yield put(pollsActions.setSelected(response.body.poll.id));
        yield put(pollsActions.setEdit(true));
      },
    });
  } catch {}
}

function* editPollSaga(action: PayloadAction<IEditPoll>) {
  try {
    yield call(
      fetchApi,
      `/api/event/${action.payload.eventId}/admin/polls/${action.payload.pollId}`,
      {
        key: ApiPollsKeys.edit,
        method: FetchMethods.Post,
        token: action.payload.adminKey,
        body: action.payload.values,
        callback: function* (response: IFetchResult<IPollResponse>) {
          yield put(pollsActions.setPoll(response.body.poll));
        },
      }
    );
  } catch {}
}

function* deletePollSaga(action: PayloadAction<IPollAction>) {
  try {
    yield call(
      fetchApi,
      `/api/event/${action.payload.eventId}/admin/polls/${action.payload.pollId}`,
      {
        key: ApiPollsKeys.edit,
        method: FetchMethods.Delete,
        token: action.payload.adminKey,
        callback: function* () {
          yield put(pollsActions.removePoll(action.payload.pollId));
        },
      }
    );
  } catch {}
}

function* createPollQuestionSaga(action: PayloadAction<ICreatePollQuestion>) {
  try {
    yield call(
      fetchApi,
      `/api/event/${action.payload.eventId}/admin/polls/${action.payload.pollId}/questions`,
      {
        key: ApiPollsKeys.createQuestion,
        method: FetchMethods.Post,
        token: action.payload.adminKey,
        body: action.payload.values,
        callback: function* (response: IFetchResult<ICreatePollResponse>) {
          yield put(pollsActions.setPoll(response.body.poll));
        },
      }
    );
  } catch {}
}

function* editPollQuestionSaga(action: PayloadAction<IEditPollQuestion>) {
  try {
    yield call(
      fetchApi,
      `/api/event/${action.payload.eventId}/admin/polls/${action.payload.pollId}/questions/${action.payload.questionId}`,
      {
        key: ApiPollsKeys.edit,
        method: FetchMethods.Post,
        token: action.payload.adminKey,
        body: action.payload.values,
        callback: function* (response: IFetchResult<IPollResponse>) {
          yield put(pollsActions.setPoll(response.body.poll));
        },
      }
    );
  } catch {}
}

function* deletePollQuestionSaga(action: PayloadAction<IPollQuestionAction>) {
  try {
    yield call(
      fetchApi,
      `/api/event/${action.payload.eventId}/admin/polls/${action.payload.pollId}/questions/${action.payload.questionId}`,
      {
        key: ApiPollsKeys.edit,
        method: FetchMethods.Delete,
        token: action.payload.adminKey,
        callback: function* (response: IFetchResult<IPollResponse>) {
          yield put(pollsActions.setPoll(response.body.poll));
        },
      }
    );
  } catch {}
}

function* createPollQuestionOptionSaga(
  action: PayloadAction<ICreatePollQuestionOption>
) {
  try {
    yield call(
      fetchApi,
      `/api/event/${action.payload.eventId}/admin/polls/${action.payload.pollId}/questions/${action.payload.questionId}/options`,
      {
        key: buildCreateQuestionOptionKey(action.payload.questionId),
        method: FetchMethods.Post,
        token: action.payload.adminKey,
        body: action.payload.values,
        callback: function* (response: IFetchResult<ICreatePollResponse>) {
          yield put(pollsActions.setPoll(response.body.poll));
        },
      }
    );
  } catch {}
}

function* editPollQuestionOptionSaga(
  action: PayloadAction<IEditPollQuestionOption>
) {
  try {
    yield call(
      fetchApi,
      `/api/event/${action.payload.eventId}/admin/polls/${action.payload.pollId}/questions/${action.payload.questionId}/options/${action.payload.optionId}`,
      {
        key: ApiPollsKeys.edit,
        method: FetchMethods.Post,
        token: action.payload.adminKey,
        body: action.payload.values,
        callback: function* (response: IFetchResult<IPollResponse>) {
          yield put(pollsActions.setPoll(response.body.poll));
        },
      }
    );
  } catch {}
}

function* deletePollQuestionOptionSaga(
  action: PayloadAction<IPollQuestionOptionAction>
) {
  try {
    yield call(
      fetchApi,
      `/api/event/${action.payload.eventId}/admin/polls/${action.payload.pollId}/questions/${action.payload.questionId}/options/${action.payload.optionId}`,
      {
        key: ApiPollsKeys.edit,
        token: action.payload.adminKey,
        method: FetchMethods.Delete,
        callback: function* (response: IFetchResult<IPollResponse>) {
          yield put(pollsActions.setPoll(response.body.poll));
        },
      }
    );
  } catch {}
}

function* clearOptionAvatarSaga(
  action: PayloadAction<IPollQuestionOptionAction>
) {
  try {
    yield call(
      fetchApi,
      `/api/event/${action.payload.eventId}/admin/polls/${action.payload.pollId}/questions/${action.payload.questionId}/options/${action.payload.optionId}/clear`,
      {
        key: ApiPollsKeys.clearOptionAvatar,
        method: FetchMethods.Post,
        token: action.payload.adminKey,
        callback: function* (response: IFetchResult<IPollResponse>) {
          yield put(pollsActions.setPoll(response.body.poll));
        },
      }
    );
  } catch {}
}

function* fetchPollResultsSaga(action: PayloadAction<IPollEventAction>) {
  try {
    const polls: IPoll[] | undefined = yield select(selectPolls);
    const selected: string | symbol | undefined = yield select(selectSelected);

    const poll = polls?.find((poll) => poll.id === selected);

    if (!poll || poll.state === "awaiting" || typeof selected !== "string") {
      return;
    }

    yield call(
      fetchApi,
      `/api/event/${action.payload.eventId}/admin/polls/${selected}/results`,
      {
        key: ApiPollsKeys.fetchResults,
        method: FetchMethods.Get,
        token: action.payload.adminKey,
        callback: function* (
          response: IFetchResult<IPollVotingResultsResponse>
        ) {
          yield put(pollsActions.setPollResults(response.body.pollResults));
        },
      }
    );
  } catch {}
}

function* fetchAllPollResultsSaga(action: PayloadAction<IPollEventAction>) {
  try {
    yield call(
      fetchApi,
      `/api/event/${action.payload.eventId}/admin/polls/results`,
      {
        key: ApiPollsKeys.fetchAllResults,
        method: FetchMethods.Get,
        token: action.payload.adminKey,
        callback: function* (response: IFetchResult<IPollsResultsResponse>) {
          yield put(pollsActions.setAllPollResults(response.body.pollsResults));
        },
      }
    );
  } catch {}
}

function* sendPollSaga(action: PayloadAction<IPollAction>) {
  try {
    yield call(
      fetchApi,
      `/api/event/${action.payload.eventId}/admin/polls/${action.payload.pollId}/send`,
      {
        key: ApiPollsKeys.sendPoll,
        method: FetchMethods.Post,
        token: action.payload.adminKey,
        callback: function* (response: IFetchResult<IPollResponse>) {
          yield put(pollsActions.setPoll(response.body.poll));
        },
      }
    );
  } catch {}
}

function* endPollSaga(action: PayloadAction<IPollAction>) {
  try {
    yield call(
      fetchApi,
      `/api/event/${action.payload.eventId}/admin/polls/${action.payload.pollId}/end`,
      {
        key: ApiPollsKeys.endPoll,
        method: FetchMethods.Post,
        token: action.payload.adminKey,
        callback: function* (response: IFetchResult<IPollResponse>) {
          yield put(pollsActions.setPoll(response.body.poll));
        },
      }
    );
  } catch {}
}

function* fetchAllVotingResultsSaga(
  action: PayloadAction<IPollEventAction & { pollIds: string[] }>
) {
  try {
    yield put(apiActions.startRequest(ApiPollsKeys.fetchAllVotingResults));

    const responses: IFetchResult<IPollVotingResultsResponse>[] = yield all(
      action.payload.pollIds.map((pollId) =>
        call(
          fetchApi,
          `/api/event/${action.payload.eventId}/admin/polls/${pollId}/results`,
          {
            key: buildFetchAllVotingResultsKey(pollId),
            method: FetchMethods.Get,
            token: action.payload.adminKey,
          }
        )
      )
    );

    const data: { [id: string]: IPollVotingResults } = {};

    responses.forEach((response, index) => {
      const pollId = action.payload.pollIds[index];
      data[pollId] = response.body.pollResults;
    });

    yield put(pollsActions.setAllVotingResults(data));
    yield put(
      apiActions.completeRequest({ key: ApiPollsKeys.fetchAllVotingResults })
    );
  } catch (error) {
    yield put(
      apiActions.completeRequest({
        key: ApiPollsKeys.fetchAllVotingResults,
        errors: error instanceof ApiErrors ? error.errors : [error.toString()],
      })
    );
  }
}

function* sendPollsSaga(action: ReturnType<typeof pollsActions.sendPolls>) {
  try {
    yield call(
      fetchApi,
      `/api/event/${action.payload.eventId}/admin/polls/send`,
      {
        key: buildSendPollsKey(action.payload.eventId),
        method: FetchMethods.Post,
        token: action.payload.adminKey,
        body: action.payload.values,
        callback: function* (response: IFetchResult<IFetchPollsResponse>) {
          yield put(pollsActions.setPolls(response.body.polls));
        },
      }
    );
  } catch {}
}

export function* pollsSagas() {
  yield all([
    takeLatest(pollsActions.fetch.type, fetchSaga),
    takeLatest(pollsActions.createPoll.type, createPollSaga),
    takeLatest(pollsActions.editPoll.type, editPollSaga),
    takeLatest(pollsActions.deletePoll.type, deletePollSaga),
    takeLatest(pollsActions.createPollQuestion.type, createPollQuestionSaga),
    takeLatest(pollsActions.editPollQuestion.type, editPollQuestionSaga),
    takeLatest(pollsActions.deletePollQuestion.type, deletePollQuestionSaga),
    takeLatest(
      pollsActions.createPollQuestionOption.type,
      createPollQuestionOptionSaga
    ),
    takeLatest(
      pollsActions.editPollQuestionOption.type,
      editPollQuestionOptionSaga
    ),
    takeLatest(
      pollsActions.deletePollQuestionOption.type,
      deletePollQuestionOptionSaga
    ),
    takeLatest(pollsActions.fetchPollResults.type, fetchPollResultsSaga),
    takeLatest(pollsActions.fetchAllPollResults.type, fetchAllPollResultsSaga),
    takeLatest(pollsActions.sendPoll.type, sendPollSaga),
    takeLatest(pollsActions.endPoll.type, endPollSaga),
    takeLatest(pollsActions.clearOptionAvatar.type, clearOptionAvatarSaga),
    takeLatest(
      pollsActions.fetchAllVotingResults.type,
      fetchAllVotingResultsSaga
    ),
    takeLatest(pollsActions.sendPolls.type, sendPollsSaga),
  ]);
}
