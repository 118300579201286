import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IUnsubscribeState {}

const initialState: IUnsubscribeState = {};

const unsubscribeSlice = createSlice({
  name: "unsubscribe",
  initialState,
  reducers: {
    unsubscribe(_, __: PayloadAction<string>) {},
  },
});

export const unsubscribeReducer = unsubscribeSlice.reducer;
export const unsubscribeActions = unsubscribeSlice.actions;
