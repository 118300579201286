/* eslint-disable */
const path = require("path");
const HttpApi = require("i18next-http-backend");

module.exports = {
  i18n: {
    defaultLocale: "en",
    locales: ["en", "fr"],
  },
  localePath: path.resolve("./public/static/locales"),
  use: process.browser ? [HttpApi] : [],
};
