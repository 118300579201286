import { all, call, put, takeLatest } from "@redux-saga/core/effects";
// eslint-disable-next-line import/no-named-as-default
import Router from "next/router";
import { Errors } from "../../../api/errors";
import { ApiErrors, fetchApi, FetchMethods, IFetchResult } from "../api/sagas";
import { snackbarActions } from "../snackbar";
import { ApiJoinPublicKeys } from "./keys";
import {
  IEventJoinPubliclyShareTokenCheckResponse,
  IEventJoinPubliclyResponse,
} from "./types";
import { joinPublicActions } from ".";

function* getCheckSaga(action: ReturnType<typeof joinPublicActions.getCheck>) {
  try {
    yield call(fetchApi, `/api/meeting/public/${action.payload}`, {
      key: ApiJoinPublicKeys.getCheck,
      callback: function* (
        response: IFetchResult<IEventJoinPubliclyShareTokenCheckResponse>
      ) {
        yield put(joinPublicActions.setCheck(response.body));
      },
    });
  } catch (error) {
    if (
      error instanceof ApiErrors &&
      error.errors.includes(Errors.EventNotFound)
    ) {
      yield Router.push("/meeting");
    }

    yield put(snackbarActions.displayError(error));
  }
}

function* submitJoinSaga(
  action: ReturnType<typeof joinPublicActions.submitJoin>
) {
  try {
    yield call(fetchApi, `/api/meeting/public/${action.payload.shareToken}`, {
      body: action.payload.values,
      method: FetchMethods.Post,
      key: ApiJoinPublicKeys.submitJoin,
      callback: function* (_: IFetchResult<IEventJoinPubliclyResponse>) {
        yield put(
          snackbarActions.queue({
            message: "checkEmailForInvitation",
            options: {
              variant: "success",
            },
          })
        );
      },
    });
  } catch (error) {
    yield put(snackbarActions.displayError(error));
  }
}

export function* joinPublicSagas() {
  yield all([
    takeLatest(joinPublicActions.getCheck.type, getCheckSaga),
    takeLatest(joinPublicActions.submitJoin.type, submitJoinSaga),
  ]);
}
