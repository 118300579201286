import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IEventJoinPubliclyShareTokenCheckResponse,
  IEventJoinPubliclyPayload,
} from "./types";

interface IJoinPublicState {
  shareTokenCheck?: IEventJoinPubliclyShareTokenCheckResponse;
}

const initialState: IJoinPublicState = {};

const joinPublicSlice = createSlice({
  name: "joinPublic",
  initialState,
  reducers: {
    getCheck(state, _: PayloadAction<string>) {
      state.shareTokenCheck = undefined;
    },
    setCheck(
      state,
      action: PayloadAction<IEventJoinPubliclyShareTokenCheckResponse>
    ) {
      state.shareTokenCheck = action.payload;
    },
    submitJoin(_, __: PayloadAction<IEventJoinPubliclyPayload>) {},
  },
});

export const joinPublicReducer = joinPublicSlice.reducer;
export const joinPublicActions = joinPublicSlice.actions;
