const letters = "abcdefghikllmnopqrstuvwxyz";
const numbers = "0123456789";

const allCharacters = [...letters, ...letters.toUpperCase(), ...numbers];

// Not crypto-secure. Use "crypto-random-string" for secure strings
export function generateRandomString(
  length: number,
  characters: string | string[]
) {
  let value = "";

  for (let index = 0; index < length; index++) {
    value += characters[Math.floor(Math.random() * characters.length)];
  }

  return value;
}

export function generateRandomId() {
  return generateRandomString(8, allCharacters);
}
