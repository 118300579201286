import { OrganizationTier } from "@prisma/client";
import { all, call, put, takeLatest } from "@redux-saga/core/effects";
// eslint-disable-next-line import/no-named-as-default
import Router from "next/router";
import { IOrganizationUsersResponse } from "../../../api/types/organization";
import { fetchApi, FetchMethods, IFetchResult } from "../api/sagas";
import { billingActions } from "../billing";
import { snackbarActions } from "../snackbar";
import { ApiOrganizationUsersKeys, buildUpdateUserKey } from "./keys";
import { organizationUsersActions } from ".";

function* fetchUsersSaga() {
  try {
    yield call(fetchApi, "/api/organization/users", {
      key: ApiOrganizationUsersKeys.fetchUsers,
      callback: function* (response: IFetchResult<IOrganizationUsersResponse>) {
        yield put(organizationUsersActions.setUsers(response.body.users));
      },
    });
  } catch (error) {
    yield put(snackbarActions.displayError(error));
  }
}

function* inviteUserSaga(
  action: ReturnType<typeof organizationUsersActions.inviteUser>
) {
  try {
    yield call(fetchApi, "/api/organization/users", {
      key: ApiOrganizationUsersKeys.inviteUser,
      method: FetchMethods.Post,
      body: {
        members: [action.payload],
      },
      callback: function* (response: IFetchResult<IOrganizationUsersResponse>) {
        yield put(organizationUsersActions.setUsers(response.body.users));

        yield put(
          snackbarActions.queue({
            message: "invitedOrganizationUser",
            options: {
              variant: "success",
            },
          })
        );
      },
    });
  } catch (error) {
    yield put(snackbarActions.displayError(error));
  }
}

function* inviteUsersSaga(
  action: ReturnType<typeof organizationUsersActions.inviteUsers>
) {
  try {
    yield call(fetchApi, "/api/organization/users", {
      key: ApiOrganizationUsersKeys.inviteUsers,
      method: FetchMethods.Post,
      body: { members: action.payload.members },
      callback: function* (response: IFetchResult<IOrganizationUsersResponse>) {
        yield put(organizationUsersActions.setUsers(response.body.users));
      },
    });

    const subscribePayload = action.payload.subscribe;
    if (
      subscribePayload?.plan === OrganizationTier.pro ||
      subscribePayload?.plan === OrganizationTier.basic
    ) {
      yield put(billingActions.subscribe(subscribePayload));
    } else {
      yield Router.push("/");
    }
  } catch {}
}

function* updateUserSaga(
  action: ReturnType<typeof organizationUsersActions.updateUser>
) {
  try {
    yield call(fetchApi, `/api/organization/users/${action.payload[0]}`, {
      key: buildUpdateUserKey(action.payload[0]),
      method: FetchMethods.Post,
      body: action.payload[1],
      callback: function* (response: IFetchResult<IOrganizationUsersResponse>) {
        yield put(organizationUsersActions.setUsers(response.body.users));

        yield put(
          snackbarActions.queue({
            message: "updatedOrganizationUser",
            options: {
              variant: "success",
            },
          })
        );
      },
    });
  } catch (error) {
    yield put(snackbarActions.displayError(error));
  }
}

function* deleteUserSaga(
  action: ReturnType<typeof organizationUsersActions.deleteUser>
) {
  try {
    yield call(fetchApi, `/api/organization/users/${action.payload}`, {
      key: buildUpdateUserKey(action.payload),
      method: FetchMethods.Delete,
      callback: function* (response: IFetchResult<IOrganizationUsersResponse>) {
        yield put(organizationUsersActions.setUsers(response.body.users));

        yield put(
          snackbarActions.queue({
            message: "deletedOrganizationUser",
            options: {
              variant: "success",
            },
          })
        );
      },
    });
  } catch (error) {
    yield put(snackbarActions.displayError(error));
  }
}

export function* organizationUsersSagas() {
  yield all([
    takeLatest(organizationUsersActions.fetchUsers.type, fetchUsersSaga),
    takeLatest(organizationUsersActions.inviteUser.type, inviteUserSaga),
    takeLatest(organizationUsersActions.inviteUsers.type, inviteUsersSaga),
    takeLatest(organizationUsersActions.updateUser.type, updateUserSaga),
    takeLatest(organizationUsersActions.deleteUser.type, deleteUserSaga),
  ]);
}
