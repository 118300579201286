import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPricesResponse, ITierPricesResponse } from "../../../types/billing";
import { ISubscribePayload } from "./types";

interface IBillingState {
  prices?: IPricesResponse;
  products?: ITierPricesResponse;
}

const billingSlice = createSlice({
  name: "billing",
  initialState: {} as IBillingState,
  reducers: {
    subscribe(_, __: PayloadAction<ISubscribePayload>) {},
    portal() {},
    fetchPrices() {},
    fetchProductPrices() {},
    setPrices(state, action: PayloadAction<IPricesResponse>) {
      state.prices = action.payload;
    },
    setProductPrices(state, action: PayloadAction<ITierPricesResponse>) {
      state.products = action.payload;
    },
  },
});

export const billingReducer = billingSlice.reducer;
export const billingActions = billingSlice.actions;
