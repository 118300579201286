import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
  icon: {
    width: "1em",
    lineHeight: 1,
  },
}));

export default function SquareIcon(props: FontAwesomeIconProps) {
  const classes = useStyles();

  return (
    <div className={classes.icon}>
      <FontAwesomeIcon {...props} />
    </div>
  );
}
