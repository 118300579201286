import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IClickmeetingInfo,
  IEvent,
  IEventBasicPayload,
  IEventParticipant,
  IEventVotersParticipation,
  IFetchEventRecordingsResponse,
  IFetchEventsResponse,
} from "../../../api/types/event";
import { IEventResults } from "../../../api/types/poll";
import {
  IChangeAgenda,
  IDeleteDocument,
  IDeleteMinutes,
  IEditAgenda,
  IEditAppearance,
  IEditBasic,
  IEditEmails,
  IDuplicateEvent,
  IEditParticipantEmailPayload,
  IEditProperty,
  IEventAdminAction,
  IKickParticipant,
  IResendInvitation,
  ITestEmail,
  IEventBuyProducts,
  IEventCheckPayment,
  IEventAddUnits,
} from "./types";

interface IEventsState {
  all?: IFetchEventsResponse;
  event?: IEvent;
  recordings?: IFetchEventRecordingsResponse;
  participants?: IEventParticipant[];
  results?: IEventResults;
  votersParticipation?: IEventVotersParticipation;
  clickmeeting?: IClickmeetingInfo;
  pandaDocSession?: string;
  timezones?: string[];
  showMinutesDialog?: boolean;
}

const initialState: IEventsState = {};

const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    create(_, __: PayloadAction<IEventBasicPayload>) {},
    editAgenda(_, __: PayloadAction<IEditAgenda>) {},
    editProperty(_, __: PayloadAction<IEditProperty>) {},
    nextDocuments(_, __: PayloadAction<string>) {},
    nextVoters(_, __: PayloadAction<string>) {},
    setAppearance(_, __: PayloadAction<IEditAppearance>) {},
    nextReview(_, __: PayloadAction<string>) {},
    deleteDocument(_, __: PayloadAction<IDeleteDocument>) {},
    editEmails(_, __: PayloadAction<IEditEmails>) {},
    deleteMinutes(_, __: PayloadAction<IDeleteMinutes>) {},
    setEvent(state, action: PayloadAction<IEvent>) {
      if (state.event?.id !== action.payload.id) {
        state.participants = undefined;
        state.results = undefined;
        state.votersParticipation = undefined;
        state.clickmeeting = undefined;
        state.pandaDocSession = undefined;
        state.recordings = undefined;
      }
      state.event = action.payload;
    },
    setEvents(state, action: PayloadAction<IFetchEventsResponse>) {
      state.all = action.payload;
    },
    setParticipants(state, action: PayloadAction<IEventParticipant[]>) {
      state.participants = action.payload;
    },
    setRecordings(state, action: PayloadAction<IFetchEventRecordingsResponse>) {
      state.recordings = action.payload;
    },
    setMinutesDialog(state, action: PayloadAction<boolean>) {
      state.showMinutesDialog = action.payload;
    },
    fetch(_, __: PayloadAction<string>) {},
    fetchParticipants(_, __: PayloadAction<IEventAdminAction>) {},
    fetchRecordings(_, __: PayloadAction<string>) {},
    fetchAll() {},
    fetchClickmeeting(state, __: PayloadAction<string>) {
      state.clickmeeting = undefined;
    },
    setClickmeeting(state, action: PayloadAction<IClickmeetingInfo>) {
      state.clickmeeting = action.payload;
    },
    editBasic(_, __: PayloadAction<IEditBasic>) {},
    start(_, __: PayloadAction<IEventAdminAction>) {},
    end(_, __: PayloadAction<IEventAdminAction>) {},
    delete(_, __: PayloadAction<string>) {},
    duplicate(_, __: PayloadAction<IDuplicateEvent>) {},
    fetchEventResults(_, __: PayloadAction<IEventAdminAction>) {},
    setEventResults(state, action: PayloadAction<IEventResults>) {
      state.results = action.payload;
    },
    resendInvitation(_, __: PayloadAction<IResendInvitation>) {},
    kick(state, action: PayloadAction<IKickParticipant>) {
      state.participants?.some((participant) => {
        if (participant.id === action.payload.participantId) {
          participant.kicked = true;
          return true;
        }
      });
    },
    sendInvitations(_, __: PayloadAction<string>) {},
    editParticipantEmail(_, __: PayloadAction<IEditParticipantEmailPayload>) {},
    fetchVotersParticipation(_, __: PayloadAction<string>) {},
    setVotersParticipation(
      state,
      action: PayloadAction<IEventVotersParticipation>
    ) {
      state.votersParticipation = action.payload;
    },
    signReport(_, __: PayloadAction<string>) {},
    setPandadocSession(state, action: PayloadAction<string>) {
      state.pandaDocSession = action.payload;
    },
    completedSigning(_, __: PayloadAction<string>) {},
    sendSignedReport(_, __: PayloadAction<string>) {},
    changeAgenda(_, __: PayloadAction<IChangeAgenda>) {},
    sendTestEmail(_, __: PayloadAction<ITestEmail>) {},
    fetchTimezones() {},
    setTimezones(state, action: PayloadAction<string[]>) {
      state.timezones = action.payload;
    },
    deleteHeader(_, __: PayloadAction<string>) {},
    deleteBackground(_, __: PayloadAction<string>) {},
    afterUploadMinutes(_, __: PayloadAction<string>) {},
    sendMinutes(_, __: PayloadAction<string>) {},
    buyProducts(_, __: PayloadAction<IEventBuyProducts>) {},
    skipPayment(_, __: PayloadAction<string>) {},
    checkPayment(_, __: PayloadAction<IEventCheckPayment>) {},
    addUnits(_, __: PayloadAction<IEventAddUnits>) {},
  },
});

export const eventsReducer = eventsSlice.reducer;
export const eventsActions = eventsSlice.actions;
