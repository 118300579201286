import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IEventVoter } from "../../../api/types/event";
import { IAddVoters, ICopyVoters, IDeleteVoter, IEditVoter } from "./types";

interface IVotersState {
  eventId?: string;
  voters?: IEventVoter[];
}

const initialState: IVotersState = {};

const votersSlice = createSlice({
  name: "voters",
  initialState,
  reducers: {
    reset(state) {
      state.eventId = undefined;
      state.voters = undefined;
    },
    addVoters(_, __: PayloadAction<IAddVoters>) {},
    editVoter(_, __: PayloadAction<IEditVoter>) {},
    deleteVoter(_, __: PayloadAction<IDeleteVoter>) {},
    setVoters(state, action: PayloadAction<[string, IEventVoter[]]>) {
      state.eventId = action.payload[0];
      state.voters = action.payload[1];
    },
    fetchVoters(_, __: PayloadAction<string>) {},
    copyVoters(_, __: PayloadAction<ICopyVoters>) {},
  },
});

export const votersReducer = votersSlice.reducer;
export const votersActions = votersSlice.actions;
