import { IAgenda, IMeetingPoll } from "../../../api/types/meeting";
import { IParticipation, IQuorum } from "../../../api/types/poll";

export enum IReceivedMessageType {
  SendPoll = "sendPoll",
  EndPoll = "endPoll",
  Kick = "kick",
  Agenda = "agenda",
  Start = "start",
  End = "end",
  Ping = "ping",
}

export interface ISendPollMessage {
  type: IReceivedMessageType.SendPoll;
  poll: IMeetingPoll;
}

export interface IEndPollMessage {
  type: IReceivedMessageType.EndPoll;
  pollId: string;
}

export interface IAgendaMessage {
  type: IReceivedMessageType.Agenda;
  agenda: IAgenda;
}

export interface IKickMessage {
  type: IReceivedMessageType.Kick;
}

export interface IStartMessage {
  type: IReceivedMessageType.Start;
}

export interface IEndMessage {
  type: IReceivedMessageType.End;
}

export interface IPingMessage {
  type: IReceivedMessageType.Ping;
}

export type ReceivedMessage =
  | ISendPollMessage
  | IEndPollMessage
  | IKickMessage
  | IAgendaMessage
  | IStartMessage
  | IEndMessage
  | IPingMessage;

export enum ConnectionState {
  disconnected,
  connecting,
  connected,
}

export interface IMeetingResults {
  participation: IParticipation;
  quorum: IQuorum;
  activeConnections?: string[];
}

export interface IMeetingResultsResponse {
  meetingResults: IMeetingResults;
}

export interface ICompletedPoll {
  voterIds: Array<string>;
  pollId: string;
}
