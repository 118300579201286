import { RootState } from "../../index";

const selectEventsState = (state: RootState) => state.events;

export const selectEvent = (state: RootState) => selectEventsState(state).event;
export const selectParticipants = (state: RootState) =>
  selectEventsState(state).participants;
export const selectClickmeeting = (state: RootState) =>
  selectEventsState(state).clickmeeting;
export const selectAllEvents = (state: RootState) =>
  selectEventsState(state).all;
export const selectEventResults = (state: RootState) =>
  selectEventsState(state).results;
export const selectVotersParticipation = (state: RootState) =>
  selectEventsState(state).votersParticipation;
export const selectPandadocSession = (state: RootState) =>
  selectEventsState(state).pandaDocSession;
export const selectTimezones = (state: RootState) =>
  selectEventsState(state).timezones;
export const selectRecordings = (state: RootState) =>
  selectEventsState(state).recordings;
export const selectMinutesDialog = (state: RootState) =>
  selectEventsState(state).showMinutesDialog;
