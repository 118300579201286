export enum ApiVoterKeys {
  addVoters = "voters/addVoters",
  editVoter = "voters/editVoter",
  deleteVoter = "voters/deleteVoter",
  copyVoters = "voters/copyVoters",
}

export function buildAddVotersKey(eventId: string) {
  return `${ApiVoterKeys.addVoters}/${eventId}`;
}

export function buildEditVoterKey(eventId: string) {
  return `${ApiVoterKeys.editVoter}/${eventId}`;
}

export function buildDeleteVoterKey(eventId: string) {
  return `${ApiVoterKeys.deleteVoter}/${eventId}`;
}

export function buildCopyVotersKey(eventId: string, from: string) {
  return `${ApiVoterKeys.copyVoters}/${eventId}/${from}`;
}
