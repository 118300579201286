export enum ApiEventKeys {
  fetch = "event/fetch",
  fetchVoters = "event/fetchVoters",
  fetchParticipants = "event/fetchParticipants",
  fetchAll = "event/fetchAll",
  fetchClickmeeting = "event/fetchClickmeeting",
  create = "event/create",
  basicForm = "event/basicForm",
  propertyForm = "event/propertyForm",
  documentsForm = "event/documents",
  setAppearance = "event/setAppearance",
  deleteDocument = "event/deleteDocument",
  votersForm = "event/votersForm",
  agendaForm = "event/agendaForm",
  start = "event/start",
  end = "event/end",
  delete = "event/delete",
  duplicate = "event/duplicate",
  fetchEventResults = "event/fetchEventResults",
  resendInvitation = "event/resendInvitation",
  kick = "event/kick",
  sendInvitations = "event/sendInvitations",
  editParticipantEmail = "event/editParticipantEmail",
  generateReport = "event/generateReport",
  fetchVotersParticipation = "event/fetchVotersParticipation",
  reviewForm = "event/review",
  signReport = "event/signReport",
  completedSigning = "event/completedSigning",
  sendSignedReport = "event/sendSignedReport",
  changeAgenda = "event/changeAgenda",
  sendTestEmail = "event/sendTestEmail",
  fetchTimezones = "event/fetchTimezones",
  fetchRecordings = "event/fetchRecordings",
  deleteBackground = "event/deleteBackground",
  deleteHeader = "event/deleteHeader",
  emails = "event/email",
  deleteMinutes = "event/deleteMinutes",
  afterUploadMinutes = "event/afterUploadMinutes",
  sendMinutes = "event/sendMinutes",
  buyProducts = "event/buyProducts",
  skipPayment = "event/skipPayment",
  checkPayment = "event/checkPayment",
  addUnits = "event/addUnits",
}

export function buildEventBasicFormKey(eventId: string) {
  return `${ApiEventKeys.basicForm}/${eventId}`;
}

export function buildEventVotersFormKey(eventId: string) {
  return `${ApiEventKeys.votersForm}/${eventId}`;
}

export function buildEventAgendaFormKey(eventId: string) {
  return `${ApiEventKeys.agendaForm}/${eventId}`;
}

export function buildEventPropertyFormKey(eventId: string) {
  return `${ApiEventKeys.propertyForm}/${eventId}`;
}

export function buildEventDocumentsFormKey(eventId: string) {
  return `${ApiEventKeys.documentsForm}/${eventId}`;
}

export function buildEventEmailsFormKey(eventId: string) {
  return `${ApiEventKeys.emails}/${eventId}`;
}

export function buildEventSetAppearanceKey(eventId: string) {
  return `${ApiEventKeys.setAppearance}/${eventId}`;
}

export function buildDeleteDocumentsKey(eventId: string) {
  return `${ApiEventKeys.deleteDocument}/${eventId}`;
}

export function buildEventReviewFormKey(eventId: string) {
  return `${ApiEventKeys.reviewForm}/${eventId}`;
}

export function buildStartEventKey(eventId: string) {
  return `${ApiEventKeys.start}/${eventId}`;
}

export function buildEndEventKey(eventId: string) {
  return `${ApiEventKeys.end}/${eventId}`;
}

export function buildDeleteEventKey(eventId: string) {
  return `${ApiEventKeys.delete}/${eventId}`;
}

export function buildFetchEventKey(eventId: string) {
  return `${ApiEventKeys.fetch}/${eventId}`;
}

export function buildFetchEventVotersKey(eventId: string) {
  return `${ApiEventKeys.fetchVoters}/${eventId}`;
}

export function buildFetchEventParticipantsKey(eventId: string) {
  return `${ApiEventKeys.fetchParticipants}/${eventId}`;
}

export function buildFetchEventResultsKey(eventId: string) {
  return `${ApiEventKeys.fetchEventResults}/${eventId}`;
}

export function buildGenerateReportKey(eventId: string) {
  return `${ApiEventKeys.generateReport}/${eventId}`;
}

export function buildFetchVotersParticiationKey(eventId: string) {
  return `${ApiEventKeys.fetchVotersParticipation}/${eventId}`;
}

export function buildSignReportKey(eventId: string) {
  return `${ApiEventKeys.signReport}/${eventId}`;
}

export function buildCompletedSigningKey(eventId: string) {
  return `${ApiEventKeys.completedSigning}/${eventId}`;
}

export function buildSendSignedReportKey(eventId: string) {
  return `${ApiEventKeys.sendSignedReport}/${eventId}`;
}

export function buildFetchClickmeetingKey(eventId: string) {
  return `${ApiEventKeys.fetchClickmeeting}/${eventId}`;
}

export function buildSendInvitationsKey(eventId: string) {
  return `${ApiEventKeys.sendInvitations}/${eventId}`;
}

export function buildResendInvitationKey(eventId: string) {
  return `${ApiEventKeys.resendInvitation}/${eventId}`;
}

export function buildKickKey(eventId: string) {
  return `${ApiEventKeys.kick}/${eventId}`;
}

export function buildEditParticipantEmailKey(eventId: string) {
  return `${ApiEventKeys.editParticipantEmail}/${eventId}`;
}

export function buildChangeAgendaKey(eventId: string) {
  return `${ApiEventKeys.changeAgenda}/${eventId}`;
}

export function buildSendTestEmailKey(eventId: string) {
  return `${ApiEventKeys.sendTestEmail}/${eventId}`;
}

export function buildFetchRecordingsKey(eventId: string) {
  return `${ApiEventKeys.fetchRecordings}/${eventId}`;
}

export function buildDeleteBackgroundKey(eventId: string) {
  return `${ApiEventKeys.deleteBackground}/${eventId}`;
}

export function buildDeleteHeaderKey(eventId: string) {
  return `${ApiEventKeys.deleteHeader}/${eventId}`;
}

export function buildDeleteMinutesKey(eventId: string) {
  return `${ApiEventKeys.deleteMinutes}/${eventId}`;
}

export function buildAfterUploadMinutesKey(eventId: string) {
  return `${ApiEventKeys.afterUploadMinutes}/${eventId}`;
}

export function buildSendMinutesKey(eventId: string) {
  return `${ApiEventKeys.sendMinutes}/${eventId}`;
}

export function buildDuplicateEventKey(eventId: string) {
  return `${ApiEventKeys.duplicate}/${eventId}`;
}

export function buildBuyProductsKey(eventId: string) {
  return `${ApiEventKeys.buyProducts}/${eventId}`;
}

export function buildSkipPaymentKey(eventId: string) {
  return `${ApiEventKeys.skipPayment}/${eventId}`;
}

export function buildCheckPaymentKey(eventId: string) {
  return `${ApiEventKeys.checkPayment}/${eventId}`;
}

export function buildAddUnitsKey(eventId: string) {
  return `${ApiEventKeys.addUnits}/${eventId}`;
}
