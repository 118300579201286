import { all, call, put, takeLatest } from "@redux-saga/core/effects";
import {
  IOrganizationResponse,
  IWhoamiResponse,
} from "../../../api/types/authentication";
import { fetchApi, FetchMethods, IFetchResult } from "../api/sagas";
import { snackbarActions } from "../snackbar";
import { ApiUserKeys } from "./keys";
import { userActions } from ".";

function* editProfileSettingsSaga(
  action: ReturnType<typeof userActions.editProfileSettings>
) {
  try {
    const response: IFetchResult<IWhoamiResponse> = yield call(
      fetchApi,
      "/api/user/settings",
      {
        body: action.payload,
        method: FetchMethods.Post,
        key: ApiUserKeys.editProfileSettings,
      }
    );

    yield put(userActions.setUser(response.body.user));
    yield put(
      snackbarActions.queue({
        message: "settingsSaved",
        options: {
          variant: "success",
        },
      })
    );
  } catch (error) {
    yield put(snackbarActions.displayError(error));
  }
}

function* editLanguageSaga(
  action: ReturnType<typeof userActions.editLanguage>
) {
  try {
    const response: IFetchResult<IWhoamiResponse> = yield call(
      fetchApi,
      "/api/user/language",
      {
        body: {
          language: action.payload,
        },
        method: FetchMethods.Post,
        key: ApiUserKeys.editLanguage,
      }
    );

    yield put(userActions.setUser(response.body.user));
  } catch (error) {
    yield put(snackbarActions.displayError(error));
  }
}

function* editOrganizationSettingsSaga(
  action: ReturnType<typeof userActions.editOrganizationSettings>
) {
  try {
    const response: IFetchResult<IOrganizationResponse> = yield call(
      fetchApi,
      "/api/organization/settings",
      {
        body: action.payload,
        method: FetchMethods.Post,
        key: ApiUserKeys.editOrganizationSettings,
      }
    );

    yield put(userActions.setOrganization(response.body.organization));
    yield put(
      snackbarActions.queue({
        message: "settingsSaved",
        options: {
          variant: "success",
        },
      })
    );
  } catch (error) {
    yield put(snackbarActions.displayError(error));
  }
}

function* clearOrganizationAvatarSaga() {
  try {
    yield call(fetchApi, "/api/user/avatar/clear", {
      method: FetchMethods.Post,
      key: ApiUserKeys.clearOrganizationAvatar,
      callback: function* (response: IFetchResult<IWhoamiResponse>) {
        yield put(userActions.setUser(response.body.user));
      },
    });
  } catch {}
}

export function* userSagas() {
  yield all([
    takeLatest(userActions.editLanguage.type, editLanguageSaga),
    takeLatest(userActions.editProfileSettings.type, editProfileSettingsSaga),
    takeLatest(
      userActions.editOrganizationSettings.type,
      editOrganizationSettingsSaga
    ),
    takeLatest(
      userActions.clearOrganizationAvatar.type,
      clearOrganizationAvatarSaga
    ),
  ]);
}
