export interface IErrorsResponse {
  errors: string[];
  statusCode?: number;
}

// TODO: Now that Prisma's enum can be used on client-side, replace with Prisma models' state enum
export enum State {
  awaiting = "awaiting",
  ongoing = "ongoing",
  completed = "completed",
}

export interface IFetchTimezones {
  timezones: string[];
}

export enum ParticipantType {
  Voter = "voter",
  Mandate = "mandate",
}
