import { NextRouter } from "next/router";

export function initializeGoogleAnalytics(trackingId: string, url: string) {
  const script = document.createElement("script");
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;

  document.getElementsByTagName("head")[0].appendChild(script);

  window.gtag?.("config", trackingId, {
    page_path: url,
  });
}

export function gaPageview(url: string) {
  window.gtag?.("event", "page_view", {
    page_path: url,
  });
}

interface IEventOptions {
  action?: string;
  category?: string;
  label?: string;
  value?: string;
}

export function gaEvent({ action, category, label, value }: IEventOptions) {
  window.gtag?.("event", action, {
    event_category: category,
    event_label: label,
    value: value,
  });
}

export function initGa(router: NextRouter) {
  router.events.on("routeChangeComplete", (url) => {
    setTimeout(() => gaPageview(url), 0);
  });
}
