import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOrganizationUser } from "../../../api/types/organization";
import {
  IOrganizationUserInvitationPayload,
  IOrganizationUsersInvitationPayload,
  IOrganizationUserUpdatePayload,
} from "../../../utils/validation/organization";

interface IOrganizationUsersState {
  users?: Array<IOrganizationUser>;
}

const initialState: IOrganizationUsersState = {
  users: undefined,
};

const organizationUsersSlice = createSlice({
  name: "organizationUsers",
  initialState,
  reducers: {
    fetchUsers() {},
    setUsers(state, action: PayloadAction<Array<IOrganizationUser>>) {
      state.users = action.payload;
    },
    inviteUser(_, __: PayloadAction<IOrganizationUserInvitationPayload>) {},
    inviteUsers(_, __: PayloadAction<IOrganizationUsersInvitationPayload>) {},
    updateUser(
      _,
      __: PayloadAction<[string, IOrganizationUserUpdatePayload]>
    ) {},
    deleteUser(_, __: PayloadAction<string>) {},
  },
});

export const organizationUsersReducer = organizationUsersSlice.reducer;
export const organizationUsersActions = organizationUsersSlice.actions;
