import type {} from "@material-ui/lab/themeAugmentation";
import { createTheme } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

const primary = "#56c3cf";

const completed = "#3EB095";

const grey200 = "#e1e6f0";

const hint = "#727A8C";

const error = "#FE4542";

export default createTheme({
  typography: {
    fontFamily: [
      "Montserrat",
      "BlinkMacSystemFont",
      "-apple-system",
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Droid Sans",
      "Helvetica Neue",
      "Helvetica",
      "sans-serif",
    ].join(","),
    overline: {
      fontSize: "1.2em",
      color: grey["600"],
      textTransform: "none",
    },
    h1: {
      fontWeight: 500,
      fontSize: "22px",
      color: "black",
      lineHeight: "104px",
    },
    h2: {
      fontWeight: 500,
      fontSize: "26px",
    },
    h3: {
      fontWeight: 500,
      fontSize: "22px",
    },
    subtitle1: {
      fontSize: "1.3em",
      // lineHeight: 1,
      fontWeight: 500,
    },
    caption: {
      color: hint,
    },
  },
  palette: {
    primary: {
      main: primary,
      light: "#c4f9ff",
      contrastText: "#fff",
      100: "#F2F8FC",
      200: "#13B5C4",
      300: "#16B3C2",
    },
    secondary: {
      main: "#FFF",
      contrastText: "#515B6D",
    },
    grey: {
      // All mixed up because of inconsistent designs
      100: "#f9fbfc",
      200: grey200,
      300: "#727A8C",
      400: "#727A8C",
      500: "#4A4A4A",
      600: "#E1E6F0",
      700: "#BDBDBD",
      800: "#F2F8FC",
      A100: "#C2C5CD",
    },
    success: {
      main: completed, // Missing color
      dark: "#2d806c",
      contrastText: "#fff",
    },
    text: {
      secondary: "#515B6d",
      hint,
    },
    warning: {
      contrastText: "#fff",
      main: "#FFB917",
    },
    background: {
      default: "#fff",
    },
    error: {
      main: error,
      dark: "#b3312e",
    },
    info: {
      main: "#1973E7",
    },
  },
  props: {
    MuiTextField: {
      variant: "outlined",
    },
    MuiCardHeader: {
      titleTypographyProps: { variant: "h2" },
    },
    MuiDialogTitle: {
      disableTypography: true,
    },
    MuiCard: {
      variant: "outlined",
    },
  },
  overrides: {
    MuiInputLabel: {
      root: {
        fontWeight: 500,
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: "#e8eaf0",
        borderWidth: "2px",
      },
      root: {
        "&:hover $notchedOutline": {
          borderColor: "#d2d4d9",
        },
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
        marginRight: 0,
        marginTop: 5,
      },
    },
    MuiLink: {
      underlineAlways: {
        textDecoration: "none",
        underline: "none",
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        "&$checked": {
          color: primary,
        },
      },
    },
    MuiRadio: {
      colorSecondary: {
        "&$checked": {
          color: primary,
        },
      },
    },
    MuiStepIcon: {
      root: {
        "&$completed": {
          color: primary,
          border: "none",
        },
        "&$active": {
          color: "#FFFFFF",
          borderColor: primary,
        },
        color: "#FFFFFF",
        borderStyle: "solid",
        borderSize: "2px",
        borderColor: grey200,
        borderRadius: "50%",
      },
    },
    MuiStepper: {
      root: {
        padding: 0,
        align: "center",
      },
    },
    MuiStepConnector: {
      alternativeLabel: {
        top: 10,
        left: "calc(-50% + 12px)",
        right: "calc(50% + 12px)",
      },
      active: {
        "& $line": {
          backgroundColor: primary,
          borderColor: primary,
        },
      },
      completed: {
        "& $line": {
          backgroundColor: primary,
          borderColor: primary,
        },
      },
      line: {
        height: 3,
        border: 0,
        borderRadius: 1,
        backgroundColor: grey200,
        borderColor: grey200,
      },
      lineVertical: {
        width: 0,
      },
    },
    MuiCardContent: {
      root: {
        padding: "24px",
      },
    },
    MuiCardHeader: {
      root: {
        padding: "24px 24px 0 24px",
      },
      content: {
        width: "100%",
      },
    },
    MuiDialog: {
      paper: {
        "@media(max-width: 960px)": {
          margin: 16,
        },
        "& > form": {
          display: "contents",
        },
      },
      paperFullWidth: {
        "@media(max-width: 960px)": {
          width: "calc(100% - 32px)",
        },
      },
    },
    MuiDialogContent: {
      root: {
        padding: "32px",
      },
    },
    MuiDialogTitle: {
      root: {
        padding: "32px 32px 8px 32px",
      },
    },
    MuiDialogActions: {
      root: {
        padding: "8px 32px 32px 32px",
      },
    },
    MuiTooltip: {
      tooltip: {
        padding: 0,
      },
      touch: {
        padding: 0,
      },
    },
    MuiTabPanel: {
      root: {
        padding: 0,
        paddingTop: 24,
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
      },
      contained: {
        backgroundColor: "#454954",
        "&:hover": {
          backgroundColor: "#959aa6",
        },
      },
      outlined: {
        borderColor: "#e8eaf0",
        borderWidth: "2px",
        "&:hover": {
          borderColor: "#d2d4d9",
        },
      },
    },
    MuiSkeleton: {
      text: {
        borderRadius: "15px",
      },
    },
  },
});

/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention */
declare module "@material-ui/core/styles/overrides" {
  export interface ComponentNameToClassKey {
    MuiTabPanel: any;
  }
}
