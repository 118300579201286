import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeLatest,
} from "@redux-saga/core/effects";
// eslint-disable-next-line import/no-named-as-default
import Router from "next/router";
import { Errors } from "../../../api/errors";
import { IFetchTimezones } from "../../../api/types";
import {
  ICreateEventResponse,
  IEditEventResponse,
  IEditVotersResponse,
  IEventParticipantsResponse,
  IEventSignResponse,
  IEventVoter,
  IEventVotersParticipationResponse,
  IEventVotersResponse,
  IFetchClickmeetingResponse,
  IFetchEventRecordingsResponse,
  IFetchEventResponse,
  IFetchEventsResponse,
} from "../../../api/types/event";
import { IMeeting } from "../../../api/types/meeting";
import { IEventResultsResponse } from "../../../api/types/poll";
import { IRedirectResponse } from "../../../types/billing";
import { apiActions } from "../api";
import { fetchApi, FetchMethods, IFetchResult } from "../api/sagas";
import { getIsLoadingSelector } from "../api/selectors";
import { meetingActions } from "../meeting";
import { selectMeeting } from "../meeting/selectors";
import { pollsActions } from "../polls";
import { snackbarActions } from "../snackbar";
import { votersActions } from "../voters";
import { selectVoters } from "../voters/selectors";
import {
  ApiEventKeys,
  buildChangeAgendaKey,
  buildCompletedSigningKey,
  buildDeleteBackgroundKey,
  buildDeleteDocumentsKey,
  buildDeleteEventKey,
  buildDeleteHeaderKey,
  buildEditParticipantEmailKey,
  buildEndEventKey,
  buildEventAgendaFormKey,
  buildEventBasicFormKey,
  buildEventDocumentsFormKey,
  buildEventEmailsFormKey,
  buildEventPropertyFormKey,
  buildEventReviewFormKey,
  buildEventSetAppearanceKey,
  buildFetchClickmeetingKey,
  buildFetchEventKey,
  buildFetchEventParticipantsKey,
  buildFetchEventResultsKey,
  buildFetchRecordingsKey,
  buildFetchVotersParticiationKey,
  buildGenerateReportKey,
  buildKickKey,
  buildResendInvitationKey,
  buildSendInvitationsKey,
  buildSendSignedReportKey,
  buildSendTestEmailKey,
  buildSignReportKey,
  buildStartEventKey,
  buildDeleteMinutesKey,
  buildSendMinutesKey,
  buildAfterUploadMinutesKey,
  buildDuplicateEventKey,
  buildBuyProductsKey,
  buildSkipPaymentKey,
  buildAddUnitsKey,
} from "./keys";
import { eventsActions } from ".";

function* createSaga(action: ReturnType<typeof eventsActions.create>) {
  try {
    const response: IFetchResult<ICreateEventResponse> = yield call(
      fetchApi,
      "/api/event",
      {
        body: action.payload,
        method: FetchMethods.Post,
        key: ApiEventKeys.create,
        callback: function* (response: IFetchResult<ICreateEventResponse>) {
          yield put(eventsActions.setEvent(response.body.event));
          yield put(votersActions.reset());
        },
      }
    );

    yield Router.push(
      "/event/[eventId]/create",
      `/event/${response.body.event.id}/create`
    );
  } catch {}
}

function* duplicateSaga(action: ReturnType<typeof eventsActions.duplicate>) {
  try {
    yield call(
      fetchApi,
      `/api/event/${action.payload.eventId}/actions/duplicate`,
      {
        body: {
          participants: action.payload.participants,
          documents: action.payload.documents,
          agenda: action.payload.agenda,
          polls: action.payload.polls,
        },
        method: FetchMethods.Post,
        key: buildDuplicateEventKey(action.payload.eventId),
        callback: function* (response: IFetchResult<ICreateEventResponse>) {
          yield Router.push(
            "/event/[eventId]/create",
            `/event/${response.body.event.id}/create`
          );
        },
      }
    );
  } catch {}
}

function* editAgendaSaga(action: ReturnType<typeof eventsActions.editAgenda>) {
  try {
    yield call(fetchApi, `/api/event/${action.payload.eventId}/admin/agenda`, {
      body: action.payload.values,
      method: FetchMethods.Post,
      key: buildEventAgendaFormKey(action.payload.eventId),
      token: action.payload.adminKey,
      callback: function* (response: IFetchResult<IEditEventResponse>) {
        const meeting = (yield select(selectMeeting)) as IMeeting | undefined;

        if (!meeting) {
          yield put(eventsActions.setEvent(response.body.event));
        } else {
          yield put(
            meetingActions.setMeeting({
              ...meeting,
              event: {
                ...meeting.event,
                agenda: response.body.event.agenda,
              },
            })
          );
        }
      },
    });
  } catch {}
}

function* editPropertySaga(
  action: ReturnType<typeof eventsActions.editProperty>
) {
  try {
    yield call(fetchApi, `/api/event/${action.payload.eventId}/property`, {
      body: action.payload.values,
      method: FetchMethods.Post,
      key: buildEventPropertyFormKey(action.payload.eventId),
      callback: function* (response: IFetchResult<IEditEventResponse>) {
        yield put(eventsActions.setEvent(response.body.event));
      },
    });
  } catch {}
}

function* nextDocumentsSaga(
  action: ReturnType<typeof eventsActions.nextDocuments>
) {
  try {
    yield call(fetchApi, `/api/event/${action.payload}/documents/next`, {
      method: FetchMethods.Post,
      key: buildEventDocumentsFormKey(action.payload),
      callback: function* (response: IFetchResult<IEditEventResponse>) {
        yield put(eventsActions.setEvent(response.body.event));
      },
    });
  } catch {}
}

function* setAppearanceSaga(
  action: ReturnType<typeof eventsActions.setAppearance>
) {
  try {
    yield call(fetchApi, `/api/event/${action.payload.eventId}/appearance`, {
      method: FetchMethods.Post,
      key: buildEventSetAppearanceKey(action.payload.eventId),
      body: action.payload.values,
      callback: function* (response: IFetchResult<IEditEventResponse>) {
        yield put(eventsActions.setEvent(response.body.event));
      },
    });

    if (action.payload.showNotification) {
      yield put(
        snackbarActions.queue({
          message: "eventAppearanceSaved",
          options: {
            variant: "success",
          },
        })
      );
    }
  } catch {}
}

function* nextVotersSaga(action: ReturnType<typeof eventsActions.nextVoters>) {
  try {
    const voters: IEventVoter[] = yield select(selectVoters);

    if (!voters?.length) {
      yield put(
        snackbarActions.queue({
          ns: "errors",
          message: Errors.EventVotersRequired,
          options: {
            variant: "error",
          },
        })
      );

      return;
    }

    yield call(fetchApi, `/api/event/${action.payload}/actions/voters-next`, {
      method: FetchMethods.Post,
      key: buildEventReviewFormKey(action.payload),
      callback: function* (response: IFetchResult<IEditEventResponse>) {
        yield put(eventsActions.setEvent(response.body.event));
      },
    });
  } catch {}
}

function* nextReviewSaga(action: ReturnType<typeof eventsActions.nextReview>) {
  try {
    yield call(fetchApi, `/api/event/${action.payload}/actions/review-next`, {
      method: FetchMethods.Post,
      key: buildEventReviewFormKey(action.payload),
      callback: function* (response: IFetchResult<IEditEventResponse>) {
        yield put(eventsActions.setEvent(response.body.event));
      },
    });
  } catch {}
}

function* deleteDocumentSaga(
  action: ReturnType<typeof eventsActions.deleteDocument>
) {
  try {
    yield call(
      fetchApi,
      `/api/event/${action.payload.eventId}/documents/delete/${action.payload.documentId}`,
      {
        method: FetchMethods.Post,
        key: buildDeleteDocumentsKey(action.payload.eventId),
        callback: function* (response: IFetchResult<IEditEventResponse>) {
          yield put(eventsActions.setEvent(response.body.event));
        },
      }
    );
  } catch {}
}

function* deleteMinutesSaga(
  action: ReturnType<typeof eventsActions.deleteMinutes>
) {
  try {
    yield call(
      fetchApi,
      `/api/event/${action.payload.eventId}/minutes/delete`,
      {
        method: FetchMethods.Delete,
        key: buildDeleteMinutesKey(action.payload.eventId),
        callback: function* (response: IFetchResult<IEditEventResponse>) {
          yield put(eventsActions.setEvent(response.body.event));
        },
      }
    );
  } catch {}
}

function* fetchSaga(action: ReturnType<typeof eventsActions.fetch>) {
  try {
    yield call(fetchApi, `/api/event/${action.payload}`, {
      key: buildFetchEventKey(action.payload),
      callback: function* (response: IFetchResult<IFetchEventResponse>) {
        yield put(eventsActions.setEvent(response.body.event));
      },
    });
  } catch {}
}

function* fetchParticipantsSaga(
  action: ReturnType<typeof eventsActions.fetchParticipants>
) {
  try {
    yield call(
      fetchApi,
      `/api/event/${action.payload.eventId}/admin/participants`,
      {
        key: buildFetchEventParticipantsKey(action.payload.eventId),
        token: action.payload.adminKey,
        callback: function* (
          response: IFetchResult<IEventParticipantsResponse>
        ) {
          yield put(eventsActions.setParticipants(response.body.participants));
        },
      }
    );
  } catch {}
}

function* fetchAllSaga() {
  try {
    const response: IFetchResult<IFetchEventsResponse> = yield call(
      fetchApi,
      `/api/event/all`,
      {
        key: ApiEventKeys.fetchAll,
      }
    );

    yield put(eventsActions.setEvents(response.body));
  } catch {}
}

function* fetchClickmeetingSaga(
  action: ReturnType<typeof eventsActions.fetchClickmeeting>
) {
  try {
    const response: IFetchResult<IFetchClickmeetingResponse> = yield call(
      fetchApi,
      `/api/event/${action.payload}/clickmeeting`,
      {
        key: buildFetchClickmeetingKey(action.payload),
      }
    );

    yield put(eventsActions.setClickmeeting(response.body.clickmeeting));
  } catch {}
}

function* editBasicSaga(action: ReturnType<typeof eventsActions.editBasic>) {
  try {
    yield call(
      fetchApi,
      `/api/event/${
        action.payload.eventId
      }/basic?sendInvitations=${encodeURIComponent(
        action.payload.sendInvitations || false
      )}`,
      {
        body: action.payload.values,
        method: FetchMethods.Post,
        key: buildEventBasicFormKey(action.payload.eventId),
        callback: function* (response: IFetchResult<IEditEventResponse>) {
          yield put(eventsActions.setEvent(response.body.event));
        },
      }
    );

    if (action.payload.showNotification) {
      yield put(
        snackbarActions.queue({
          message: "eventSettingsSaved",
          options: {
            variant: "success",
          },
        })
      );
    }
  } catch {}
}

function* editEmailsSaga(action: ReturnType<typeof eventsActions.editEmails>) {
  try {
    yield call(fetchApi, `/api/event/${action.payload.eventId}/emails`, {
      body: action.payload.values,
      method: FetchMethods.Post,
      key: buildEventEmailsFormKey(action.payload.eventId),
      callback: function* (response: IFetchResult<IEditEventResponse>) {
        yield put(eventsActions.setEvent(response.body.event));
      },
    });

    if (action.payload.showNotification) {
      yield put(
        snackbarActions.queue({
          message: "eventEmailsSaved",
          options: {
            variant: "success",
          },
        })
      );
    }
  } catch {}
}

function* startSaga(action: ReturnType<typeof eventsActions.start>) {
  try {
    Router.prefetch(
      "/event/[eventId]/meeting",
      `/event/${action.payload.eventId}/meeting`
    );

    const response: IFetchResult<IEditEventResponse> = yield call(
      fetchApi,
      `/api/event/${action.payload.eventId}/actions/start`,
      {
        method: FetchMethods.Post,
        key: buildStartEventKey(action.payload.eventId),
        callback: function* (response: IFetchResult<IEditEventResponse>) {
          yield put(eventsActions.setEvent(response.body.event));
        },
      }
    );

    // Reload data
    yield put(
      pollsActions.fetch({
        eventId: action.payload.eventId,
        adminKey: action.payload.adminKey,
      })
    );
    yield put(
      eventsActions.fetchParticipants({
        eventId: action.payload.eventId,
        adminKey: action.payload.adminKey,
      })
    );
    yield put(votersActions.fetchVoters(action.payload.eventId));

    yield Router.push(
      "/event/[eventId]/meeting",
      `/event/${response.body.event.id}/meeting`
    );
  } catch {}
}

function* endSaga(action: ReturnType<typeof eventsActions.end>) {
  try {
    yield call(fetchApi, `/api/event/${action.payload.eventId}/actions/end`, {
      method: FetchMethods.Post,
      key: buildEndEventKey(action.payload.eventId),
      callback: function* (response: IFetchResult<IEditEventResponse>) {
        yield put(eventsActions.setEvent(response.body.event));
      },
    });

    // Reload data
    yield put(
      eventsActions.fetchEventResults({
        eventId: action.payload.eventId,
        adminKey: action.payload.adminKey,
      })
    );
    yield put(
      pollsActions.fetch({
        eventId: action.payload.eventId,
        adminKey: action.payload.adminKey,
      })
    );
    yield put(
      pollsActions.fetchAllPollResults({
        eventId: action.payload.eventId,
        adminKey: action.payload.adminKey,
      })
    );
    yield put(votersActions.fetchVoters(action.payload.eventId));
    yield put(eventsActions.fetchVotersParticipation(action.payload.eventId));
    yield put(
      eventsActions.fetchParticipants({
        eventId: action.payload.eventId,
        adminKey: action.payload.adminKey,
      })
    );

    // Start generating report in background
    yield fork(
      fetchApi,
      `/api/event/${action.payload.eventId}/report/download?no-download`,
      {
        method: FetchMethods.Get,
        noParse: true,
        key: buildGenerateReportKey(action.payload.eventId),
      }
    );
  } catch {}
}

function* deleteSaga(action: ReturnType<typeof eventsActions.delete>) {
  try {
    yield call(fetchApi, `/api/event/${action.payload}`, {
      method: FetchMethods.Delete,
      key: buildDeleteEventKey(action.payload),
      callback: function* () {
        yield put(eventsActions.setEvent(undefined));
      },
    });

    yield put(
      snackbarActions.queue({
        message: "deletedEvent",
        options: {
          variant: "success",
        },
      })
    );

    if (["/", "/en", "/fr"].includes(window.location.pathname)) {
      yield call(fetchAllSaga);
    } else {
      yield Router.push("/");
    }
  } catch {}
}

function* fetchEventResultsSaga(
  action: ReturnType<typeof eventsActions.fetchEventResults>
) {
  try {
    yield call(fetchApi, `/api/event/${action.payload.eventId}/admin/results`, {
      key: buildFetchEventResultsKey(action.payload.eventId),
      method: FetchMethods.Get,
      token: action.payload.adminKey,
      callback: function* (response: IFetchResult<IEventResultsResponse>) {
        yield put(eventsActions.setEventResults(response.body.eventResults));
      },
    });
  } catch {}
}

function* resendInvitationSaga(
  action: ReturnType<typeof eventsActions.resendInvitation>
) {
  try {
    yield call(
      fetchApi,
      `/api/event/${action.payload.eventId}/admin/participants/invite/${action.payload.participantId}`,
      {
        key: buildResendInvitationKey(action.payload.eventId),
        method: FetchMethods.Post,
        token: action.payload.adminKey,
        callback: function* (response: IFetchResult<IEventVotersResponse>) {
          yield put(
            votersActions.setVoters([
              action.payload.eventId,
              response.body.voters,
            ])
          );
          const voter = response.body.voters.find(
            (voter) => voter.participantId === action.payload.participantId
          );

          const mandate = response.body.voters.find(
            (voter) => voter.mandate?.id === action.payload.participantId
          )?.mandate;

          if (!voter && !mandate) {
            return;
          }

          if (voter) {
            yield put(
              snackbarActions.queue({
                message: "invitationResent",
                messageOptions: {
                  name: voter.name,
                },
                options: {
                  variant: "success",
                },
              })
            );
          }

          if (!voter && mandate) {
            yield put(
              snackbarActions.queue({
                message: "invitationResent",
                messageOptions: {
                  name: mandate.name,
                },
                options: {
                  variant: "success",
                },
              })
            );
          }
        },
      }
    );
  } catch {}
}

function* kickSaga(action: ReturnType<typeof eventsActions.kick>) {
  try {
    yield call(
      fetchApi,
      `/api/event/${action.payload.eventId}/admin/participants/kick/${action.payload.participantId}`,
      {
        key: buildKickKey(action.payload.eventId),
        method: FetchMethods.Post,
        token: action.payload.adminKey,
      }
    );
  } catch {}
}

function* sendInvitationsSaga(
  action: ReturnType<typeof eventsActions.sendInvitations>
) {
  try {
    yield call(
      fetchApi,
      `/api/event/${action.payload}/actions/send-invitations`,
      {
        key: buildSendInvitationsKey(action.payload),
        method: FetchMethods.Post,
        callback: function* (response: IFetchResult<IEditVotersResponse>) {
          yield put(eventsActions.setEvent(response.body.event));
          yield put(
            votersActions.setVoters([action.payload, response.body.voters])
          );
        },
      }
    );
  } catch {}
}

function* editParticipantEmailSaga(
  action: ReturnType<typeof eventsActions.editParticipantEmail>
) {
  try {
    yield call(
      fetchApi,
      `/api/event/${action.payload.eventId}/admin/participants/edit-email/${action.payload.participantId}`,
      {
        key: buildEditParticipantEmailKey(action.payload.eventId),
        method: FetchMethods.Post,
        body: {
          email: action.payload.email,
        },
        token: action.payload.adminKey,
        callback: function* (
          response: IFetchResult<IEventParticipantsResponse>
        ) {
          yield put(eventsActions.setParticipants(response.body.participants));
        },
      }
    );
  } catch (error) {
    yield put(snackbarActions.displayError(error));
  }
}

function* fetchVotersParticipationSaga(
  action: ReturnType<typeof eventsActions.fetchVotersParticipation>
) {
  try {
    yield call(
      fetchApi,
      `/api/event/${action.payload}/report/voter-participation`,
      {
        key: buildFetchVotersParticiationKey(action.payload),
        method: FetchMethods.Get,
        callback: function* (
          response: IFetchResult<IEventVotersParticipationResponse>
        ) {
          yield put(
            eventsActions.setVotersParticipation(
              response.body.votersParticipation
            )
          );
        },
      }
    );
  } catch {}
}

function* signReportSaga(action: ReturnType<typeof eventsActions.signReport>) {
  try {
    yield call(fetchApi, `/api/event/${action.payload}/report/sign`, {
      key: buildSignReportKey(action.payload),
      method: FetchMethods.Post,
      callback: function* (response: IFetchResult<IEventSignResponse>) {
        yield put(
          eventsActions.setPandadocSession(response.body.pandaDocSession)
        );
      },
    });
  } catch {}
}

function* completedSigningSaga(
  action: ReturnType<typeof eventsActions.completedSigning>
) {
  try {
    yield call(
      fetchApi,
      `/api/event/${action.payload}/report/download-signed?no-download`,
      {
        key: buildCompletedSigningKey(action.payload),
        method: FetchMethods.Get,
        noParse: true,
        callback: function* () {
          yield put(eventsActions.fetch(action.payload));
        },
      }
    );
  } catch {}
}

function* sendSignedReportSaga(
  action: ReturnType<typeof eventsActions.sendSignedReport>
) {
  try {
    yield call(fetchApi, `/api/event/${action.payload}/report/send-signed`, {
      key: buildSendSignedReportKey(action.payload),
      method: FetchMethods.Post,
      callback: function* (response: IFetchResult<IEditEventResponse>) {
        yield put(eventsActions.setEvent(response.body.event));
      },
    });
  } catch {}
}

function* changeAgendaSaga(
  action: ReturnType<typeof eventsActions.changeAgenda>
) {
  try {
    yield call(
      fetchApi,
      `/api/event/${action.payload.eventId}/admin/agenda/current`,
      {
        key: buildChangeAgendaKey(action.payload.eventId),
        method: FetchMethods.Post,
        body: {
          current: action.payload.current,
        },
        token: action.payload.adminKey,
        callback: function* (response: IFetchResult<IEditEventResponse>) {
          const meeting = (yield select(selectMeeting)) as IMeeting | undefined;

          if (!meeting) {
            yield put(eventsActions.setEvent(response.body.event));
          } else {
            yield put(
              meetingActions.setMeeting({
                ...meeting,
                event: {
                  ...meeting.event,
                  agenda: response.body.event.agenda,
                },
              })
            );
          }
        },
      }
    );
  } catch {}
}

function* sendTestEmailSaga(
  action: ReturnType<typeof eventsActions.sendTestEmail>
) {
  try {
    // Wait until saving is done before sending the email
    const saveKey = buildEventEmailsFormKey(action.payload.eventId);
    if (yield select(getIsLoadingSelector(saveKey))) {
      yield take(
        (action) =>
          apiActions.completeRequest.type === action.type &&
          action.payload.key === saveKey
      );
    }

    yield call(
      fetchApi,
      `/api/event/${action.payload.eventId}/test-email?type=${action.payload.type}`,
      {
        key: buildSendTestEmailKey(action.payload.eventId),
        method: FetchMethods.Post,
        body: action.payload?.values || {},
        callback: function* () {
          yield put(
            snackbarActions.queue({
              message: "sentTestInvitationSuccessful",
              options: {
                variant: "success",
              },
            })
          );
        },
      }
    );
  } catch {}
}

function* fetchTimezonesSaga() {
  try {
    yield call(fetchApi, `/api/timezones`, {
      key: ApiEventKeys.fetchTimezones,
      callback: function* (response: IFetchResult<IFetchTimezones>) {
        yield put(eventsActions.setTimezones(response.body.timezones));
      },
    });
  } catch {}
}

function* fetchRecordingsSaga(
  action: ReturnType<typeof eventsActions.fetchRecordings>
) {
  try {
    yield call(fetchApi, `/api/event/${action.payload}/recordings`, {
      key: buildFetchRecordingsKey(action.payload),
      method: FetchMethods.Get,
      callback: function* (
        response: IFetchResult<IFetchEventRecordingsResponse>
      ) {
        yield put(eventsActions.setRecordings(response.body));
      },
    });
  } catch {}
}

function* deleteBackgroundSaga(
  action: ReturnType<typeof eventsActions.deleteBackground>
) {
  try {
    yield call(fetchApi, `/api/event/${action.payload}/appearance/background`, {
      method: FetchMethods.Delete,
      key: buildDeleteBackgroundKey(action.payload),
      callback: function* (response: IFetchResult<IEditEventResponse>) {
        yield put(eventsActions.setEvent(response.body.event));
      },
    });
  } catch {}
}

function* deleteHeaderSaga(
  action: ReturnType<typeof eventsActions.deleteHeader>
) {
  try {
    yield call(fetchApi, `/api/event/${action.payload}/appearance/header`, {
      method: FetchMethods.Delete,
      key: buildDeleteHeaderKey(action.payload),
      callback: function* (response: IFetchResult<IEditEventResponse>) {
        yield put(eventsActions.setEvent(response.body.event));
      },
    });
  } catch {}
}

function* afterUploadMinutesSaga(
  action: ReturnType<typeof eventsActions.afterUploadMinutes>
) {
  try {
    yield call(fetchApi, `/api/event/${action.payload}`, {
      key: buildAfterUploadMinutesKey(action.payload),
      callback: function* (response: IFetchResult<IFetchEventResponse>) {
        yield put(eventsActions.setEvent(response.body.event));

        yield put(eventsActions.setMinutesDialog(true));
      },
    });
  } catch {}
}

function* sendMinutesSaga(
  action: ReturnType<typeof eventsActions.sendMinutes>
) {
  try {
    yield call(fetchApi, `/api/event/${action.payload}/minutes/send-minutes`, {
      key: buildSendMinutesKey(action.payload),
      method: FetchMethods.Post,
      callback: function* (response: IFetchResult<IEditEventResponse>) {
        yield put(eventsActions.setEvent(response.body.event));

        yield put(
          snackbarActions.queue({
            message: "sendMinutesSuccessful",
            options: {
              variant: "success",
            },
          })
        );
      },
    });
  } catch {}
}

function* buyProductsSaga(
  action: ReturnType<typeof eventsActions.buyProducts>
) {
  try {
    yield call(fetchApi, `/api/event/${action.payload.eventId}/buy-products`, {
      body: action.payload.values,
      method: FetchMethods.Post,
      key: buildBuyProductsKey(action.payload.eventId),
      callback: function (response: IFetchResult<IRedirectResponse>) {
        location.href = response.body.url;
      },
    });
  } catch (error) {
    yield put(snackbarActions.displayError(error));
  }
}

function* skipPaymentSaga(
  action: ReturnType<typeof eventsActions.skipPayment>
) {
  try {
    yield call(fetchApi, `/api/event/${action.payload}/skip-payment`, {
      method: FetchMethods.Post,
      key: buildSkipPaymentKey(action.payload),
      callback: function* (response: IFetchResult<IFetchEventResponse>) {
        yield put(eventsActions.setEvent(response.body.event));
      },
    });
  } catch (err) {}
}

function* checkPaymentSaga(
  action: ReturnType<typeof eventsActions.checkPayment>
) {
  try {
    yield call(fetchApi, `/api/event/${action.payload.eventId}/check-payment`, {
      method: FetchMethods.Post,
      body: action.payload.values,
      key: buildSkipPaymentKey(action.payload.eventId),
      callback: function* (response: IFetchResult<IFetchEventResponse>) {
        yield put(eventsActions.setEvent(response.body.event));
      },
    });
  } catch (err) {}
}

function* addUnitsSaga(action: ReturnType<typeof eventsActions.addUnits>) {
  try {
    yield call(fetchApi, `/api/event/${action.payload.eventId}/add-units`, {
      method: FetchMethods.Post,
      key: buildAddUnitsKey(action.payload.eventId),
      body: action.payload.values,
      callback: function (response: IFetchResult<IRedirectResponse>) {
        location.href = response.body.url;
      },
    });
  } catch (error) {
    yield put(snackbarActions.displayError(error));
  }
}

export function* eventsSagas() {
  yield all([
    takeLatest(eventsActions.create.type, createSaga),
    takeLatest(eventsActions.duplicate.type, duplicateSaga),
    takeLatest(eventsActions.editProperty.type, editPropertySaga),
    takeLatest(eventsActions.nextDocuments.type, nextDocumentsSaga),
    takeLatest(eventsActions.setAppearance.type, setAppearanceSaga),
    takeLatest(eventsActions.deleteDocument.type, deleteDocumentSaga),
    takeLatest(eventsActions.editAgenda.type, editAgendaSaga),
    takeLatest(eventsActions.fetch.type, fetchSaga),
    takeLatest(eventsActions.fetchParticipants.type, fetchParticipantsSaga),
    takeLatest(eventsActions.fetchAll.type, fetchAllSaga),
    takeLatest(eventsActions.fetchClickmeeting.type, fetchClickmeetingSaga),
    takeLatest(eventsActions.editBasic.type, editBasicSaga),
    takeLatest(eventsActions.start.type, startSaga),
    takeLatest(eventsActions.end.type, endSaga),
    takeLatest(eventsActions.delete.type, deleteSaga),
    takeLatest(eventsActions.fetchEventResults.type, fetchEventResultsSaga),
    takeLatest(eventsActions.resendInvitation.type, resendInvitationSaga),
    takeLatest(eventsActions.kick.type, kickSaga),
    takeLatest(eventsActions.sendInvitations.type, sendInvitationsSaga),
    takeLatest(
      eventsActions.editParticipantEmail.type,
      editParticipantEmailSaga
    ),
    takeLatest(
      eventsActions.fetchVotersParticipation.type,
      fetchVotersParticipationSaga
    ),
    takeLatest(eventsActions.nextVoters.type, nextVotersSaga),
    takeLatest(eventsActions.nextReview.type, nextReviewSaga),
    takeLatest(eventsActions.signReport.type, signReportSaga),
    takeLatest(eventsActions.completedSigning.type, completedSigningSaga),
    takeLatest(eventsActions.sendSignedReport.type, sendSignedReportSaga),
    takeLatest(eventsActions.changeAgenda.type, changeAgendaSaga),
    takeLatest(eventsActions.sendTestEmail.type, sendTestEmailSaga),
    takeLatest(eventsActions.fetchTimezones.type, fetchTimezonesSaga),
    takeLatest(eventsActions.fetchRecordings.type, fetchRecordingsSaga),
    takeLatest(eventsActions.deleteBackground.type, deleteBackgroundSaga),
    takeLatest(eventsActions.deleteHeader.type, deleteHeaderSaga),
    takeLatest(eventsActions.editEmails.type, editEmailsSaga),
    takeLatest(eventsActions.deleteMinutes.type, deleteMinutesSaga),
    takeLatest(eventsActions.afterUploadMinutes.type, afterUploadMinutesSaga),
    takeLatest(eventsActions.sendMinutes.type, sendMinutesSaga),
    takeLatest(eventsActions.buyProducts.type, buyProductsSaga),
    takeLatest(eventsActions.skipPayment.type, skipPaymentSaga),
    takeLatest(eventsActions.checkPayment.type, checkPaymentSaga),
    takeLatest(eventsActions.addUnits.type, addUnitsSaga),
  ]);
}
