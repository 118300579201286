import { all, call, put, takeLatest } from "@redux-saga/core/effects";
import { PayloadAction } from "@reduxjs/toolkit";
// eslint-disable-next-line import/no-named-as-default
import Router from "next/router";
import {
  IForgotPasswordPayload,
  IResetPasswordPayload,
} from "../../../api/types/verify";
import { fetchApi, FetchMethods } from "../api/sagas";
import { ApiVerifyKeys } from "./keys";
import { verifyActions } from ".";

function* verifySaga(action: PayloadAction<string>) {
  try {
    yield call(fetchApi, "/api/auth/verify", {
      body: {
        token: action.payload,
      },
      method: FetchMethods.Post,
      key: ApiVerifyKeys.verify,
    });

    yield put(verifyActions.verified());
  } catch {}
}

function* verifyResetPasswordSaga(
  action: PayloadAction<IResetPasswordPayload>
) {
  try {
    yield call(fetchApi, `/api/auth/reset-password/${action.payload.token}`, {
      body: action.payload,
      method: FetchMethods.Post,
      key: ApiVerifyKeys.resetPassword,
    });

    yield Router.push({
      pathname: "/login",
      query: {
        action: "reset-password",
      },
    });
  } catch {}
}

function* forgotPasswordSaga(action: PayloadAction<IForgotPasswordPayload>) {
  try {
    yield call(fetchApi, "/api/auth/forgot-password", {
      body: action.payload,
      method: FetchMethods.Post,
      key: ApiVerifyKeys.forgotPassword,
    });
  } catch {}
}

export function* verifySagas() {
  yield all([
    takeLatest(verifyActions.verify.type, verifySaga),
    takeLatest(verifyActions.verifyResetPassword.type, verifyResetPasswordSaga),
    takeLatest(verifyActions.forgotPassword.type, forgotPasswordSaga),
  ]);
}
