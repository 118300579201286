import { all, call, put, takeLatest } from "@redux-saga/core/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  IFetchPreboardResponse,
  ISetAttendance,
  ISetMandate,
} from "../../../api/types/mandate";
import { getBowserDetails } from "../../../utils/bowser";
import { fetchApi, FetchMethods, IFetchResult } from "../api/sagas";
import { snackbarActions } from "../snackbar";
import { ApiPreboardKeys } from "./keys";
import { preboardActions } from "./index";

function* fetchPreboardSaga(action: PayloadAction<string>) {
  try {
    yield call(fetchApi, `/api/preboard/${action.payload}`, {
      method: FetchMethods.Get,
      key: ApiPreboardKeys.fetchPreboard,
      callback: function* (response: IFetchResult<IFetchPreboardResponse>) {
        yield put(preboardActions.set(response.body));
      },
    });
  } catch {}
}

function* setMandateSaga(action: PayloadAction<ISetMandate>) {
  try {
    const accessMetadata = yield getBowserDetails();

    yield call(fetchApi, `/api/preboard/${action.payload.token}/mandate`, {
      method: action.payload.mandate ? FetchMethods.Post : FetchMethods.Delete,
      key: ApiPreboardKeys.setMandate,
      body: {
        mandate: action.payload.mandate,
        signature: action.payload.signature,
        accessMetadata,
      },
      callback: function* (response: IFetchResult<IFetchPreboardResponse>) {
        yield put(preboardActions.set(response.body));
      },
    });

    yield put(
      snackbarActions.queue({
        message: action.payload.mandate ? "mandateSaved" : "mandateDeleted",
        options: {
          variant: "success",
        },
      })
    );
  } catch {}
}

function* setAttendanceSaga(action: PayloadAction<ISetAttendance>) {
  try {
    yield call(fetchApi, `/api/preboard/${action.payload.token}/attendance`, {
      method: FetchMethods.Post,
      key: ApiPreboardKeys.setAttendance,
      body: {
        attendance: action.payload.attendance,
      },
      callback: function* (response: IFetchResult<IFetchPreboardResponse>) {
        yield put(preboardActions.set(response.body));
      },
    });

    yield put(
      snackbarActions.queue({
        message: "attendanceSaved",
        options: {
          variant: "success",
        },
      })
    );
  } catch {}
}

function* donePreboardSaga(action: PayloadAction<string>) {
  try {
    yield call(fetchApi, `/api/preboard/${action.payload}/done`, {
      method: FetchMethods.Post,
      key: ApiPreboardKeys.donePreboard,
      callback: function* (response: IFetchResult<IFetchPreboardResponse>) {
        yield put(preboardActions.set(response.body));
      },
    });
  } catch {}
}

export function* preboardSagas() {
  yield all([
    takeLatest(preboardActions.fetchPreboard.type, fetchPreboardSaga),
    takeLatest(preboardActions.setMandate.type, setMandateSaga),
    takeLatest(preboardActions.setAttendance.type, setAttendanceSaga),
    takeLatest(preboardActions.donePreboard.type, donePreboardSaga),
  ]);
}
