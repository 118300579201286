import {
  all,
  call,
  delay,
  put,
  select,
  spawn,
  takeLatest,
} from "@redux-saga/core/effects";
import { PayloadAction } from "@reduxjs/toolkit";
// eslint-disable-next-line import/no-named-as-default
import Router from "next/router";
import { Errors } from "../../../api/errors";
import { IEvent, IFetchClickmeetingResponse } from "../../../api/types/event";
import {
  IHostMeeting,
  IMeeting,
  IMeetingPollResponse,
  IMeetingPollVote,
} from "../../../api/types/meeting";
import { ApiErrors, fetchApi, FetchMethods, IFetchResult } from "../api/sagas";
import { eventsActions } from "../events";
import { selectEvent } from "../events/selectors";
import { pollsActions } from "../polls";
import { snackbarActions } from "../snackbar";
import { ApiMeetingKeys } from "./keys";
import {
  selectCommonMeeting,
  selectHostMeeting,
  selectMeeting,
} from "./selectors";
import { IReceivedMessageType, ReceivedMessage } from "./types";
import { meetingActions } from "./index";

function* fetchMeetingSaga(action: PayloadAction<string>) {
  try {
    const meeting: IMeeting | undefined = yield select(selectMeeting);

    yield call(fetchApi, `/api/meeting/${action.payload}?no-dsock`, {
      method: FetchMethods.Get,
      key: ApiMeetingKeys.fetch,
      callback: function* (response: IFetchResult<IMeeting>) {
        yield put(meetingActions.setMeeting(response.body));

        // Fetch polls if previous polls didn't match
        const a = meeting?.activePolls.map((poll) => poll.id).sort();
        const b = response.body.activePolls.map((poll) => poll.id).sort();
        if (JSON.stringify(a) !== JSON.stringify(b)) {
          yield put(meetingActions.fetchPolls());
        }
      },
      expectedErrors: [Errors.EventNotOngoing],
      errorCallback: function* (error) {
        if (
          error instanceof ApiErrors &&
          error.errors.includes(Errors.EventNotOngoing)
        ) {
          yield Router.push("/meeting/ended");
        }
      },
    });
  } catch {}
}

function* fetchHostMeetingSaga(action: PayloadAction<string>) {
  const hostMeeting: IHostMeeting | undefined = yield select(selectHostMeeting);

  try {
    yield call(fetchApi, `/api/event/${action.payload}/meeting`, {
      method: FetchMethods.Get,
      key: ApiMeetingKeys.fetchHostMeeting,
      callback: function* (response: IFetchResult<IHostMeeting>) {
        yield put(meetingActions.setHostMeeting(response.body));
        yield put(eventsActions.setEvent(response.body.event));

        // Fetch polls if previous polls didn't match
        const a = hostMeeting?.activePolls.map((poll) => poll.id).sort();
        const b = response.body.activePolls?.map((poll) => poll.id).sort();
        if (JSON.stringify(a) !== JSON.stringify(b)) {
          yield put(meetingActions.fetchPolls());
        }
      },
      expectedErrors: [Errors.EventNotOngoing],
      errorCallback: function* (error) {
        if (error instanceof ApiErrors) {
          if (error.errors.includes(Errors.EventNotOngoing)) {
            const event = (yield select(selectEvent)) as IEvent | undefined;
            if (!event) {
              // Should never happen
              yield Router.push("/");
              return;
            }

            yield Router.push("/event/[eventId]", `/event/${event.id}`);
            yield put(eventsActions.fetch(event.id));
          } else if (error.errors.includes(Errors.PermissionDenied)) {
            const event = (yield select(selectEvent)) as IEvent | undefined;
            if (!event) {
              // Should never happen
              yield Router.push("/");
              return;
            }

            yield Router.push("/event/[eventId]", `/event/${event.id}`);
            yield put(eventsActions.fetch(event.id));
          }
        }
      },
    });
  } catch {}
}

function* fetchPollsSaga() {
  try {
    const meeting: IMeeting | IHostMeeting | undefined = yield select(
      selectCommonMeeting
    );

    if (!meeting?.token) {
      return;
    }

    const response: IFetchResult<IMeetingPollResponse> = yield call(
      fetchApi,
      `/api/meeting/${meeting.token}/polls`,
      {
        method: FetchMethods.Get,
        key: ApiMeetingKeys.fetchPolls,
      }
    );

    yield put(meetingActions.setPolls(response.body.voterMeetingPolls));
  } catch {}
}

function* reconnectSaga() {
  try {
    // PM
    const event: IEvent | undefined = yield select(selectEvent);
    if (event) {
      yield put(meetingActions.fetchHostMeeting(event.id));
      return;
    }

    // CO
    const meeting: IMeeting | undefined = yield select(selectMeeting);
    if (meeting) {
      yield put(meetingActions.fetchMeeting(meeting.token));
      return;
    }
  } catch {}
}

function* submitPollSaga(action: PayloadAction<IMeetingPollVote>) {
  try {
    const meeting: IMeeting | IHostMeeting | undefined = yield select(
      selectCommonMeeting
    );

    if (!meeting?.token) {
      return;
    }

    yield call(
      fetchApi,
      `/api/meeting/${meeting.token}/submit/${action.payload.pollId}`,
      {
        body: {
          voterIds: action.payload.voterIds,
          questions: action.payload.questions,
          accessMetadata: action.payload.accessMetadata,
        },
        method: FetchMethods.Post,
        key: ApiMeetingKeys.submitPoll,
      }
    );

    yield put(meetingActions.submittedPoll(action.payload));
    yield put(
      snackbarActions.queue({
        message: "votingSuccessful",
        options: {
          variant: "success",
        },
      })
    );
  } catch (error) {
    yield put(snackbarActions.displayError(error));

    if (error instanceof ApiErrors) {
      if (error.errors.includes(Errors.AlreadyVoted)) {
        yield put(meetingActions.submittedPoll(action.payload));
      }
    }
  }
}

function* receiveMessageSaga(action: PayloadAction<ReceivedMessage>) {
  const event: IEvent | undefined = yield select(selectEvent);
  const meeting: IMeeting | undefined = yield select(selectMeeting);

  const isHost = !!event;

  switch (action.payload.type) {
    case IReceivedMessageType.SendPoll: {
      if (isHost) {
        yield put(
          pollsActions.fetch({
            eventId: event.id,
            adminKey: event.adminKey,
          })
        );
      }
      yield put(meetingActions.receivePoll(action.payload.poll));
      break;
    }
    case IReceivedMessageType.EndPoll: {
      if (isHost) {
        yield put(
          pollsActions.fetch({
            eventId: event.id,
            adminKey: event.adminKey,
          })
        );
      }
      yield put(meetingActions.endPoll(action.payload.pollId));
      break;
    }
    case IReceivedMessageType.Kick:
    case IReceivedMessageType.End: {
      if (isHost) {
        yield Router.push("/event/[eventId]", `/event/${event.id}`);
        yield put(eventsActions.fetch(event.id));
        break;
      }

      yield Router.push("/meeting/ended");
      break;
    }
    case IReceivedMessageType.Agenda: {
      if (isHost) {
        yield put(eventsActions.fetch(event.id));
        break;
      }

      yield put(meetingActions.setMeetingAgenda(action.payload.agenda));
      break;
    }
    case IReceivedMessageType.Start: {
      if (!meeting) {
        break;
      }

      yield spawn(function* () {
        // Add some delay to let the hosts connect first
        yield delay(10000);
        yield put(meetingActions.fetchMeeting(meeting.token));
      });
      break;
    }
  }
}

function* fetchClickmeetingSaga(action: PayloadAction<string>) {
  try {
    yield call(fetchApi, `/api/meeting/${action.payload}/clickmeeting`, {
      key: ApiMeetingKeys.fetchClickmeeting,
      callback: function* (response: IFetchResult<IFetchClickmeetingResponse>) {
        yield put(meetingActions.setClickmeeting(response.body.clickmeeting));
      },
    });
  } catch {}
}

function* getInvitationsSaga(
  action: ReturnType<typeof meetingActions.getInvitations>
) {
  try {
    yield call(fetchApi, `/api/meeting/invitations`, {
      key: ApiMeetingKeys.getInvitations,
      method: FetchMethods.Post,
      body: {
        input: action.payload,
      },
      callback: function* () {
        yield put(
          snackbarActions.queue({
            message: "checkEmailForInvitation",
            options: {
              variant: "success",
            },
          })
        );
      },
    });
  } catch (error) {
    yield put(snackbarActions.displayError(error));
  }
}

function* setLocalitySaga(
  action: ReturnType<typeof meetingActions.setLocality>
) {
  try {
    yield call(fetchApi, `/api/meeting/${action.payload[0]}/locality`, {
      key: ApiMeetingKeys.setLocality,
      method: FetchMethods.Post,
      body: {
        input: action.payload[1],
      },
    });
  } catch (error) {
    yield put(snackbarActions.displayError(error));
  }
}

export function* meetingSagas() {
  yield all([
    takeLatest(meetingActions.fetchMeeting.type, fetchMeetingSaga),
    takeLatest(meetingActions.fetchHostMeeting.type, fetchHostMeetingSaga),
    takeLatest(meetingActions.fetchPolls.type, fetchPollsSaga),
    takeLatest(meetingActions.submitPoll.type, submitPollSaga),
    takeLatest(meetingActions.receiveMessage.type, receiveMessageSaga),
    takeLatest(meetingActions.reconnect.type, reconnectSaga),
    takeLatest(meetingActions.fetchClickmeeting.type, fetchClickmeetingSaga),
    takeLatest(meetingActions.getInvitations.type, getInvitationsSaga),
    takeLatest(meetingActions.setLocality.type, setLocalitySaga),
  ]);
}
