export function initializeHotjar(id: string) {
  window._hjSettings = {
    hjid: parseInt(id),
    hjsv: 6,
  };

  window.hj =
    window.hj ||
    function (...args) {
      (window.hj.q = window.hj.q || []).push(...args);
    };

  const script = document.createElement("script");
  script.async = true;
  script.src = `https://static.hotjar.com/c/hotjar-${window._hjSettings.hjid}.js?sv=${window._hjSettings.hjsv}`;

  document.getElementsByTagName("head")[0].appendChild(script);
}
