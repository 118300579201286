import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IFetchPreboardResponse,
  ISetAttendance,
  ISetMandate,
} from "../../../api/types/mandate";

const initialState: { state?: IFetchPreboardResponse } = {};

const preboardSlice = createSlice({
  name: "preboard",
  initialState,
  reducers: {
    reset(state) {
      state.state = undefined;
    },
    fetchPreboard(_, __: PayloadAction<string>) {},
    set(state, action: PayloadAction<IFetchPreboardResponse>) {
      state.state = action.payload;
    },
    setMandate(_, __: PayloadAction<ISetMandate>) {},
    setAttendance(_, __: PayloadAction<ISetAttendance>) {},
    donePreboard(_, __: PayloadAction<string>) {},
  },
});

export const preboardReducer = preboardSlice.reducer;
export const preboardActions = preboardSlice.actions;
