import { all, call, put, takeLatest } from "@redux-saga/core/effects";
import { IFetchAddressAutocompleteResult } from "../../../api/types/mapbox";
import { IPropertiesResponse } from "../../../api/types/property";
import { fetchApi, FetchMethods, IFetchResult } from "../api/sagas";
import { snackbarActions } from "../snackbar";
import { ApiPropertiesKeys } from "./keys";
import { propertiesActions } from ".";

function* addPropertySaga(
  action: ReturnType<typeof propertiesActions.addProperty>
) {
  try {
    yield call(fetchApi, `/api/property`, {
      body: action.payload,
      method: FetchMethods.Post,
      key: ApiPropertiesKeys.addProperty,
      callback: function* (response: IFetchResult<IPropertiesResponse>) {
        yield put(propertiesActions.setProperties(response.body.properties));
      },
    });
  } catch (error) {
    yield put(snackbarActions.displayError(error));
  }
}

function* fetchPropertiesSaga(
  action: ReturnType<typeof propertiesActions.fetchProperties>
) {
  try {
    yield call(fetchApi, `/api/property`, {
      body: action.payload,
      method: FetchMethods.Get,
      key: ApiPropertiesKeys.fetchProperties,
      callback: function* (response: IFetchResult<IPropertiesResponse>) {
        yield put(propertiesActions.setProperties(response.body.properties));
      },
    });
  } catch (error) {
    yield put(snackbarActions.displayError(error));
  }
}

function* fetchAutocompleteResultsSaga(
  action: ReturnType<typeof propertiesActions.fetchAutocompleteResults>
) {
  try {
    yield call(
      fetchApi,
      `/api/address-autocomplete?query=${encodeURIComponent(action.payload)}`,
      {
        method: FetchMethods.Get,
        key: ApiPropertiesKeys.fetchAutocompleteResults,
        callback: function* (
          response: IFetchResult<IFetchAddressAutocompleteResult>
        ) {
          yield put(
            propertiesActions.setAutocompleteResults(response.body.results)
          );
        },
      }
    );
  } catch (error) {
    yield put(snackbarActions.displayError(error));
  }
}

export function* propertiesSagas() {
  yield all([
    takeLatest(propertiesActions.addProperty.type, addPropertySaga),
    takeLatest(propertiesActions.fetchProperties.type, fetchPropertiesSaga),
    takeLatest(
      propertiesActions.fetchAutocompleteResults.type,
      fetchAutocompleteResultsSaga
    ),
  ]);
}
