import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IForgotPasswordPayload,
  IResetPasswordPayload,
} from "../../../api/types/verify";

const verifySlice = createSlice({
  name: "auth",
  initialState: {},
  reducers: {
    verified() {},
    verify(_, __: PayloadAction<string>) {},
    verifyResetPassword(_, __: PayloadAction<IResetPasswordPayload>) {},
    forgotPassword(_, __: PayloadAction<IForgotPasswordPayload>) {},
  },
});

export const verifyReducer = verifySlice.reducer;
export const verifyActions = verifySlice.actions;
