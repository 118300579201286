import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TOptions } from "i18next";
import { OptionsObject, SnackbarKey } from "notistack";
import { extractErrors } from "../../../utils/errors";
import { generateRandomId } from "../../../utils/random";

interface ISnackbar {
  message: string;
  ns?: string;
  options: OptionsObject;
  messageOptions?: TOptions;
  dismissed: boolean;
  dismissible: boolean;
}

interface ISnackbarState {
  snackbars: ISnackbar[];
}

const initialState: ISnackbarState = {
  snackbars: [],
};

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    queue(
      state,
      action: PayloadAction<{
        message: string;
        options?: OptionsObject;
        messageOptions?: TOptions;
        dismissed?: boolean;
        dismissible?: boolean;
        ns?: string;
      }>
    ) {
      state.snackbars.push({
        dismissed: false,
        dismissible: true,
        ...action.payload,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        options: {
          key: Date.now() + "-" + generateRandomId(),
          ...action.payload.options,
        },
      });
    },
    remove(state, action: PayloadAction<SnackbarKey>) {
      state.snackbars = state.snackbars.filter(
        (snackbar) => snackbar.options.key !== action.payload
      );
    },
    dismiss(state, action: PayloadAction<SnackbarKey>) {
      state.snackbars.some((snackbar) => {
        if (snackbar.options.key === action.payload) {
          snackbar.dismissed = true;
          return true;
        }
      });
    },
    displayError(state, action: PayloadAction<unknown>) {
      const messages = extractErrors(action.payload);

      if (!messages.length) {
        return;
      }

      state.snackbars.push({
        dismissed: false,
        dismissible: true,
        ns: "errors",
        message: messages.join("\n"),
        options: {
          variant: "error",
          key: Date.now() + "-" + generateRandomId(),
        },
      });
    },
  },
});

export const snackbarReducer = snackbarSlice.reducer;
export const snackbarActions = snackbarSlice.actions;
