import { State } from "../../../api/types";
import { RootState } from "../../index";

const selectPollsState = (state: RootState) => state.polls;

export const selectPolls = (state: RootState) => selectPollsState(state).polls;
export const selectSelected = (state: RootState) =>
  selectPollsState(state).selected;
export const selectEditing = (state: RootState) =>
  selectPollsState(state).editing;
export const selectPollResults = (state: RootState) =>
  selectPollsState(state).results;
export const selectAllPollResults = (state: RootState) =>
  selectPollsState(state).allResults;
export const selectCompletedPollsCount = (state: RootState) =>
  selectPolls(state)?.reduce(
    (completed, poll) => completed + +(poll.state === State.completed),
    0
  );
export const selectAllVotingResults = (state: RootState) =>
  selectPollsState(state).allVotingResults;
