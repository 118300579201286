export enum Errors {
  UserNotFound = "USER_NOT_FOUND",
  WrongPassword = "WRONG_PASSWORD",
  UserAlreadyExists = "USER_ALREADY_EXISTS",
  RoleRequired = "ROLE_REQUIRED",
  NameRequired = "NAME_REQUIRED",
  EmailRequired = "EMAIL_REQUIRED",
  PasswordRequired = "PASSWORD_REQUIRED",
  PasswordTooShort = "PASSWORD_TOO_SHORT",
  PasswordTooWeak = "PASSWORD_TOO_WEAK",
  PasswordTooLong = "PASSWORD_TOO_LONG",
  VerificationTokenRequired = "VERIFICATION_TOKEN_REQUIRED",
  InvalidVerificationToken = "INVALID_VERIFICATION_TOKEN",
  InvalidAuthorization = "INVALID_AUTHORIZATION",
  UnknownError = "UNKNOWN_ERROR",
  SendgridVerificationError = "SENDGRID_VERIFICATION_ERROR",
  MissingFile = "MISSING_FILE",
  InvalidFileType = "INVALID_FILE_TYPE",
  LanguageRequired = "LANGUAGE_REQUIRED",
  NameTooShort = "NAME_TOO_SHORT",
  NameTooLong = "NAME_TOO_LONG",
  InvalidEmail = "INVALID_EMAIL",
  NumberOfUnitsRequired = "NUMBER_OF_UNITS_REQUIRED",
  NumberOfBoardMeetingsRequired = "NUMBER_OF_BOARD_MEETINGS_REQUIRED",
  InvalidNumberOfUnits = "INVALID_NUMBER_OF_UNITS",
  InvalidNumberOfBoardMeetings = "INVALID_NUMBER_OF_BOARD_MEETINGS",
  OrganizationRequired = "ORGANIZATION_REQUIRED",
  PhoneNumberRequired = "PHONE_NUMBER_REQUIRED",
  InvalidPhoneNumber = "INVALID_PHONE_NUMBER",
  EventNameRequired = "EVENT_NAME_REQUIRED",
  EventNotFound = "EVENT_NOT_FOUND",
  StartDateRequired = "START_DATE_REQUIRED",
  TimeZoneRequired = "TIMEZONE_REQUIRED",
  InvalidTimeZone = "INVALID_TIMEZONE",
  StartTimeRequired = "START_TIME_REQUIRED",
  EndDateRequired = "END_DATE_REQUIRED",
  InvalidDate = "INVALID_DATE",
  InvalidStartDate = "INVALID_START_DATE",
  InvalidMinEndDate = "INVALID_MIN_END_DATE",
  InvalidMaxEndDate = "INVALID_MAX_END_DATE",
  EventVotersRequired = "EVENT_VOTERS_REQUIRED",
  WeightRequired = "WEIGHT_REQUIRED",
  InvalidWeight = "INVALID_WEIGHT",
  PollNameRequired = "POLL_NAME_REQUIRED",
  PollQuestionNameRequired = "POLL_QUESTION_NAME_REQUIRED",
  PollNotFound = "POLL_NOT_FOUND",
  PollQuestionNotFound = "POLL_QUESTION_NOT_FOUND",
  PollQuestionOptionNotFound = "POLL_QUESTION_OPTION_NOT_FOUND",
  PollQuestionOptionNameRequired = "POLL_QUESTION_OPTION_NAME_REQUIRED",
  EventAlreadyStarted = "EVENT_ALREADY_STATED",
  EventNotStarted = "EVENT_NOT_STARTED",
  EventNotOngoing = "EVENT_NOT_ONGOING",
  EventIsLocked = "EVENT_IS_LOCKED",
  PropertyRequired = "PROPERTY_REQUIRED",
  PropertyNameRequired = "PROPERTY_NAME_REQUIRED",
  PropertyAddressRequired = "PROPERTY_ADDRESS_REQUIRED",
  PropertyZipCodeRequired = "PROPERTY_ZIP_CODE_REQUIRED",
  PropertyCityRequired = "PROPERTY_CITY_REQUIRED",
  PropertyCountryRequired = "PROPERTY_COUNTRY_REQUIRED",
  PropertyStateRequired = "PROPERTY_STATE_REQUIRED",
  NotEnoughOptionsSelected = "NOT_ENOUGH_OPTIONS_SELECTED",
  TooManyOptionsSelected = "TOO_MANY_OPTIONS_SELECTED",
  InvalidMethod = "INVALID_METHOD",
  AlreadyVoted = "ALREADY_VOTED",
  InvalidVote = "INVALID_VOTE",
  PollAlreadySent = "POLL_ALREADY_SENT",
  PollNotSent = "POLL_NOT_SENT",
  PollHasMinHigherThanOptions = "POLL_HAS_MIN_HIGHER_THAN_OPTIONS",
  PollHasNoQuestions = "POLL_HAS_NO_QUESTIONS",
  NumberMustBeInteger = "NUMBER_MUST_BE_INTEGER",
  MinimumRequired = "MINIMUM_REQUIRED",
  MaxiumumRequired = "MAXIMUM_REQUIRED",
  InvalidMinMax = "INVALID_MIN_MAX",
  PollEditingBlocked = "POLL_EDITING_BLOCK",
  EventEditingBlocked = "EVENT_EDITING_BLOCK",
  FileNotFound = "FILE_NOT_FOUND",
  FileTooLarge = "FILE_TOO_LARGE",
  FileAlreadyExists = "FILE_ALREADY_EXISTS",
  AlreadySentInvitations = "ALREADY_SENT_INVITATIONS",
  TooManyOngoingMeetings = "TOO_MANY_ONGOING_MEETINGS",
  AlreadyHasProxy = "ALREADY_HAS_PROXY",
  IsSuggestedRepresentative = "IS_SUGGESTED_REPRESENTATIVE",
  OnlyMandateCanVote = "ONLY_MANDATE_CAN_VOTE",
  ReportAlreadySigned = "REPORT_ALREADY_SIGNED",
  ErrorGeneratingReport = "ERROR_GENERATING_REPORT",
  ErrorGeneratingMandatePdf = "ERROR_GENERATING_MANDATE_PDF",
  ReportNotSigned = "REPORT_NOT_SIGNED",
  AgendaItemRequired = "AGENDA_ITEM_REQUIRED",
  UserRequired = "USER_REQUIRED",
  OrganizationInvitationNotFound = "ORGANIZATION_INVITATION_NOT_FOUND",
  TierRequired = "TIER_REQUIRED",
  SignatureNotFound = "SIGNATURE_NOT_FOUND",
  MandateNotFound = "MANDATE_NOT_FOUND",
  VoterNotFound = "VOTER_NOT_FOUND",
  EmailAlreadyTaken = "EMAIL_ALREADY_TAKEN",
  EventCompleted = "EVENT_COMPLETED",
  CantSetSelfAsMandate = "CANT_SET_SELF_AS_MANDATE",
  CantSetMandateIfAlreadyRepresentative = "CANT_SET_MANDATE_IF_ALREADY_REPRESENTATIVE",
  InsuffientTier = "INSUFFICIENT_TIER",
  DuplicateEmail = "DUPLICATE_EMAIL",
  TokenRequired = "TOKEN_REQUIRED",
  TokenOrEmailRequired = "TOKEN_OR_EMAIL_REQUIRED",
  EventNameTooLong = "EVENT_NAME_TOO_LONG",
  RequiredEnterprise = "REQUIRED_ENTERPRISE",
  AlreadyParticipant = "ALREADY_PARTICIPANT",
  UpgradeForMoreProperties = "UPGRADE_FOR_MORE_PROPERTIES",
  QueryRequired = "QUERY_REQUIRED",
  InvalidBillingQuantity = "INVALID_BILLING_QUANTITY",
  BillingSessionNotFound = "BILLING_SESSION_NOT_FOUND",
  InvalidPlan = "INVALID_PLAN",
  MinuteUploadBlocked = "MINUTE_UPLOAD_BLOCKED",
  PermissionDenied = "PERMISSION_DENIED",
  LocalityRequired = "LOCALITY_REQUIRED",
  NoEventSelected = "NO_EVENT_SELECTED",
  NoInviteEmailEntered = "NO_INVITE_EMAIL_ENTERED",
  ProductsRequired = "PRODUCTS_REQUIRED",
  InvalidProduct = "INVALID_PRODUCT",
  NotAllowedToAddUnits = "NOT_ALLOWED_TO_ADD_UNITS",
}
