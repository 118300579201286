import { ApiErrors } from "../store/modules/api/sagas";

export function combineErrors(...args: (string[] | undefined)[]) {
  return Array.from(new Set(args.flatMap((x) => x || [])));
}

export function extractErrors(error: unknown) {
  if (error instanceof ApiErrors) {
    return error.errors;
  } else if (error instanceof Error) {
    return [error.message];
  }

  return [error?.toString()].filter(Boolean);
}
