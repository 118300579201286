import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IPoll,
  IPollResultsMap,
  IPollVotingResults,
} from "../../../api/types/poll";
import {
  ICreatePoll,
  ICreatePollQuestion,
  ICreatePollQuestionOption,
  IEditPoll,
  IEditPollQuestion,
  IEditPollQuestionOption,
  IPollEventAction,
  IPollAction,
  IPollQuestionAction,
  IPollQuestionOptionAction,
  ISendPolls,
} from "./types";

export const createKey = Symbol.for("CreatePoll");

interface IPollsState {
  polls?: IPoll[];
  selected?: string | symbol;
  editing: boolean;
  results?: IPollVotingResults;
  allResults?: IPollResultsMap;
  allVotingResults?: { [id: string]: IPollVotingResults };
}

const initialState: IPollsState = {
  editing: false,
};

const pollsSlice = createSlice({
  name: "polls",
  initialState: initialState,
  reducers: {
    setPolls(state, action: PayloadAction<IPoll[]>) {
      state.polls = action.payload;
    },
    setPoll(state, action: PayloadAction<IPoll>) {
      // Only edit one poll
      state.polls = state.polls.map((poll) => {
        if (poll.id !== action.payload.id) {
          return poll;
        }

        return action.payload;
      });
    },
    removePoll(state, action: PayloadAction<string>) {
      state.polls = state.polls.filter((poll) => poll.id !== action.payload);
    },
    fetch(_, __: PayloadAction<IPollEventAction>) {},
    createPoll(_, __: PayloadAction<ICreatePoll>) {},
    setSelected(state, action: PayloadAction<string | symbol>) {
      state.selected = action.payload;
      state.editing = false;
      state.results = undefined;
    },
    setEdit(state, action: PayloadAction<boolean>) {
      state.editing = action.payload;
    },
    editPoll(_, __: PayloadAction<IEditPoll>) {},
    deletePoll(_, __: PayloadAction<IPollAction>) {},
    createPollQuestion(_, __: PayloadAction<ICreatePollQuestion>) {},
    editPollQuestion(_, __: PayloadAction<IEditPollQuestion>) {},
    deletePollQuestion(_, __: PayloadAction<IPollQuestionAction>) {},
    createPollQuestionOption(
      _,
      __: PayloadAction<ICreatePollQuestionOption>
    ) {},
    editPollQuestionOption(_, __: PayloadAction<IEditPollQuestionOption>) {},
    deletePollQuestionOption(
      _,
      __: PayloadAction<IPollQuestionOptionAction>
    ) {},
    fetchPollResults(_, __: PayloadAction<IPollEventAction>) {},
    setPollResults(state, action: PayloadAction<IPollVotingResults>) {
      state.results = action.payload;
    },
    fetchAllPollResults(_, __: PayloadAction<IPollEventAction>) {},
    setAllPollResults(state, action: PayloadAction<IPollResultsMap>) {
      state.allResults = action.payload;
    },
    sendPoll(_, __: PayloadAction<IPollAction>) {},
    endPoll(_, __: PayloadAction<IPollAction>) {},
    clearOptionAvatar(_, __: PayloadAction<IPollQuestionOptionAction>) {},
    fetchAllVotingResults(
      _,
      __: PayloadAction<IPollEventAction & { pollIds: string[] }>
    ) {},
    setAllVotingResults(
      state,
      action: PayloadAction<{ [id: string]: IPollVotingResults }>
    ) {
      state.allVotingResults = action.payload;
    },
    sendPolls(_, __: PayloadAction<ISendPolls>) {},
  },
});

export const pollsReducer = pollsSlice.reducer;
export const pollsActions = pollsSlice.actions;
