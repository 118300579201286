import { EventLocality } from "@prisma/client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IClickmeetingInfo } from "../../../api/types/event";
import {
  IAgenda,
  IHostMeeting,
  IMeeting,
  IMeetingPoll,
  IMeetingPollVote,
  IVoterMeetingPoll,
} from "../../../api/types/meeting";
import { ICompletedPoll, ReceivedMessage } from "./types";

interface IMeetingState {
  meeting?: IMeeting;
  hostMeeting?: IHostMeeting;
  polls?: IVoterMeetingPoll[];
  clickmeeting?: IClickmeetingInfo;
}

const initialState: IMeetingState = {};

const meetingSlice = createSlice({
  name: "meeting",
  initialState,
  reducers: {
    reset(state) {
      state.meeting = undefined;
      state.clickmeeting = undefined;
    },
    fetchMeeting(_, __: PayloadAction<string>) {},
    setMeeting(state, action: PayloadAction<IMeeting>) {
      state.meeting = action.payload;
    },
    setHostMeeting(state, action: PayloadAction<IHostMeeting>) {
      state.hostMeeting = action.payload;
    },
    receiveMessage(_, __: PayloadAction<ReceivedMessage>) {},
    receivePoll(state, action: PayloadAction<IMeetingPoll>) {
      const meeting = state.meeting || state.hostMeeting;

      if (!meeting?.represents) {
        return;
      }

      state.polls = [
        ...(state.polls?.filter(
          (poll) => poll.meetingPoll.id !== action.payload.id
        ) ?? []),
        {
          meetingPoll: action.payload,
          voterIds: meeting.represents
            .filter(
              (represent) => !represent.guest && !represent.noVotingRights
            )
            .map((represent) => represent.id),
        },
      ];
    },
    fetchPolls() {},
    setPolls(state, action: PayloadAction<IVoterMeetingPoll[]>) {
      state.polls = action.payload;
    },
    submitPoll(_, __: PayloadAction<IMeetingPollVote>) {},
    submittedPoll(state, action: PayloadAction<ICompletedPoll>) {
      state.polls = state.polls
        // Remove voter IDs from the completed poll
        ?.map((poll) => {
          if (poll.meetingPoll.id !== action.payload.pollId) {
            return poll;
          }

          poll.voterIds = poll.voterIds.filter(
            (voterId) => !action.payload.voterIds.includes(voterId)
          );

          return poll;
        })
        // Remove a fully completed poll (voted for all voters)
        ?.filter((poll) => poll.voterIds.length);
    },
    endPoll(state, action: PayloadAction<string>) {
      state.polls = state.polls?.filter(
        (poll) => poll.meetingPoll.id !== action.payload
      );
    },
    fetchHostMeeting(_, __: PayloadAction<string>) {},
    reconnect() {},
    setMeetingAgenda(state, action: PayloadAction<IAgenda>) {
      state.meeting.event.agenda = action.payload;
    },
    fetchClickmeeting(state, __: PayloadAction<string>) {
      state.clickmeeting = undefined;
    },
    setClickmeeting(state, action: PayloadAction<IClickmeetingInfo>) {
      state.clickmeeting = action.payload;
    },
    getInvitations(_, __: PayloadAction<string>) {},
    setLocality(_, __: PayloadAction<[string, EventLocality]>) {},
  },
});

export const meetingReducer = meetingSlice.reducer;
export const meetingActions = meetingSlice.actions;
