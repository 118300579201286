import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { IconButton, makeStyles } from "@material-ui/core";
import { useTranslation } from "next-i18next";
import {
  useSnackbar,
  SnackbarProvider as NotistackSnackbarProvider,
  SnackbarKey,
} from "notistack";
import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { snackbarActions } from "../store/modules/snackbar";
import { selectSnackbars } from "../store/modules/snackbar/selectors";
import SquareIcon from "./SquareIcon";

const useStyles = makeStyles((theme) => ({
  variantSuccess: {
    backgroundColor: theme.palette.success.dark,
  },
}));

export default function SnackbarProvider({
  children,
}: React.PropsWithChildren<unknown>) {
  const classes = useStyles();
  return (
    <NotistackSnackbarProvider classes={classes}>
      <SnackbarNotifier />
      {children}
    </NotistackSnackbarProvider>
  );
}

export function SnackbarNotifier() {
  const dispatch = useDispatch();
  const displayed = useRef<SnackbarKey[]>([]);
  const notifications = useSelector(selectSnackbars);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation(["snackbar", "errors"]);

  const storeDisplayed = useCallback((key: SnackbarKey) => {
    displayed.current = [...displayed.current, key];
  }, []);

  const removeDisplayed = useCallback((key: SnackbarKey) => {
    displayed.current = [
      ...displayed.current.filter((snackbarKey) => snackbarKey !== key),
    ];
  }, []);

  useEffect(() => {
    notifications.forEach(
      ({
        message,
        options = {},
        dismissed,
        dismissible,
        ns,
        messageOptions,
      }) => {
        if (dismissed) {
          closeSnackbar(options.key);
          return;
        }

        if (displayed.current.includes(options.key)) {
          return;
        }

        enqueueSnackbar(
          message
            .split("\n")
            .map((message) => t(message, { ...messageOptions, ns }))
            .join("\n"),
          {
            ...(dismissible
              ? {
                  action: (
                    <IconButton
                      onClick={() => closeSnackbar(options.key)}
                      size="small"
                    >
                      <SquareIcon icon={faTimes as IconProp} color="white" />
                    </IconButton>
                  ),
                }
              : {}),
            ...options,
            onClose: (event, reason, myKey) => {
              options.onClose?.(event, reason, myKey);
            },
            onExited: (event, myKey) => {
              dispatch(snackbarActions.remove(myKey));
              removeDisplayed(myKey);
            },
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          }
        );

        storeDisplayed(options.key);
      }
    );
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
}
