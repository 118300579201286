import { all, call, put, takeLatest } from "@redux-saga/core/effects";
import { IEventVotersResponse } from "../../../api/types/event";
import { fetchApi, FetchMethods, IFetchResult } from "../api/sagas";
import { buildFetchEventVotersKey } from "../events/keys";
import { snackbarActions } from "../snackbar";
import {
  buildAddVotersKey,
  buildCopyVotersKey,
  buildDeleteVoterKey,
  buildEditVoterKey,
} from "./keys";
import { votersActions } from ".";

function* addVotersSaga(action: ReturnType<typeof votersActions.addVoters>) {
  try {
    yield call(fetchApi, `/api/event/${action.payload.eventId}/voters`, {
      body: action.payload.values,
      method: FetchMethods.Post,
      key: buildAddVotersKey(action.payload.eventId),
      callback: function* (response: IFetchResult<IEventVotersResponse>) {
        yield put(
          votersActions.setVoters([
            action.payload.eventId,
            response.body.voters,
          ])
        );
      },
    });

    yield put(
      snackbarActions.queue({
        message: "addedVoters",
        options: {
          variant: "success",
        },
      })
    );
  } catch (error) {
    yield put(snackbarActions.displayError(error));
  }
}

function* editVoterSaga(action: ReturnType<typeof votersActions.editVoter>) {
  try {
    yield call(
      fetchApi,
      `/api/event/${action.payload.eventId}/voters/${action.payload.voterId}`,
      {
        body: action.payload.values,
        method: FetchMethods.Post,
        key: buildEditVoterKey(action.payload.eventId),
        callback: function* (response: IFetchResult<IEventVotersResponse>) {
          yield put(
            votersActions.setVoters([
              action.payload.eventId,
              response.body.voters,
            ])
          );
        },
      }
    );

    yield put(
      snackbarActions.queue({
        message: "editedVoter",
        options: {
          variant: "success",
        },
      })
    );
  } catch (error) {
    yield put(snackbarActions.displayError(error));
  }
}

function* deleteVoterSaga(
  action: ReturnType<typeof votersActions.deleteVoter>
) {
  try {
    yield call(
      fetchApi,
      `/api/event/${action.payload.eventId}/voters/${action.payload.voterId}`,
      {
        method: FetchMethods.Delete,
        key: buildDeleteVoterKey(action.payload.eventId),
        callback: function* (response: IFetchResult<IEventVotersResponse>) {
          yield put(
            votersActions.setVoters([
              action.payload.eventId,
              response.body.voters,
            ])
          );
        },
      }
    );

    yield put(
      snackbarActions.queue({
        message: "deletedVoter",
        options: {
          variant: "success",
        },
      })
    );
  } catch (error) {
    yield put(snackbarActions.displayError(error));
  }
}

function* fetchVotersSaga(
  action: ReturnType<typeof votersActions.fetchVoters>
) {
  try {
    yield call(fetchApi, `/api/event/${action.payload}/voters`, {
      key: buildFetchEventVotersKey(action.payload),
      callback: function* (response: IFetchResult<IEventVotersResponse>) {
        yield put(
          votersActions.setVoters([action.payload, response.body.voters])
        );
      },
    });
  } catch {}
}

function* copyVotersSaga(action: ReturnType<typeof votersActions.copyVoters>) {
  try {
    yield call(
      fetchApi,
      `/api/event/${action.payload.eventId}/voters/copy/${action.payload.from}`,
      {
        method: FetchMethods.Post,
        key: buildCopyVotersKey(action.payload.eventId, action.payload.from),
        callback: function* (response: IFetchResult<IEventVotersResponse>) {
          yield put(
            votersActions.setVoters([
              action.payload.eventId,
              response.body.voters,
            ])
          );
          yield put(
            snackbarActions.queue({
              message: "votersCopied",
              options: {
                variant: "success",
              },
            })
          );
        },
      }
    );
  } catch (error) {
    yield put(snackbarActions.displayError(error));
  }
}

export function* votersSagas() {
  yield all([
    takeLatest(votersActions.addVoters.type, addVotersSaga),
    takeLatest(votersActions.editVoter.type, editVoterSaga),
    takeLatest(votersActions.deleteVoter.type, deleteVoterSaga),
    takeLatest(votersActions.fetchVoters.type, fetchVotersSaga),
    takeLatest(votersActions.copyVoters.type, copyVotersSaga),
  ]);
}
